import React, { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';
import imgSquare from "../../src/assets/img/img-square-1.png";
import "../../src/assets/scss/about-us/about-us.css";
import "../../src/assets/scss/main/main.css";
import { dataUrl, get_AboutUsData, get_Metadata } from "../actions";
import MetaComponent from "../components/Elements/MetaData";
import { Footer } from "../components/Layouts/Footer/Footer";
import { Nav } from "../components/Layouts/Nav";

export function AboutUs() {
  var firstLoad = true;
  const [onloadPage, setLoading] = useState(true);
  const [aboutUsContent, setContent] = useState({
    desc: '',
    visi_content: '',
    visi_img: '',
    misi_content: '',
    misi_img: '',
  })

  const [metaData, setMeta] = useState(({
    title: '',
    deskripsi: '',
    url: window.location.href,
    image: dataUrl.konten('cb8d1aa6-53c7-4600-ba43-55975d3268f2.png'),
    keywords: 'Cipta rasa jawara, jawara, sembilan, jogja, Yogyakarta, DI Yogyakarta',
  }))

  const getData = () => {
    setLoading(true);
    get_AboutUsData((res) => {
      const {content} = res;

      setContent({
        desc: content.tentang_kami,
        visi: content.visi,
        misi: content.misi,
        visi_img: content.foto_1,
        misi_img: content.foto_2,
      })

      setLoading(false);
    })
  }

  const getMeta = () => {
    get_Metadata((res) => {
      const {meta} = res
      setMeta(value => ({...value,
        title: 'Tentang Kami | '+meta.judul_website,
        deskripsi: meta.deskripsi_page,
        image: meta.logo_page,
      }))
    })
  }


  useEffect(() => {
    if (firstLoad) {
      firstLoad = false;
      getData();
      getMeta();
    }
  }, [])

  return (
    <>
      <MetaComponent keywords={metaData.keywords} pageImage={metaData.image} pageTitle={metaData.title} pageDescription={metaData.deskripsi} url={metaData.url}></MetaComponent>
      <Nav></Nav>
      <div className="about-us">
        <div className="container">
          <div className="title-section text-center">
            <img src={imgSquare} alt={imgSquare} className="img-square"></img>
            <div>Tentang Kami</div>
          </div>
          {onloadPage ?
            <>
              <div className="desc-section mt-4 text-center">
                <Skeleton height={15} count={3} borderRadius={5}></Skeleton>
              </div>
              <div className="row align-items-center mt-5">
                <div className="col-md-6">
                  <div className="title-content"><Skeleton height={40} borderRadius={5}></Skeleton></div>
                  <div className="desc-content">
                    <Skeleton height={15} count={5} borderRadius={5}></Skeleton>
                  </div>
                </div>
                <div className="col-md-6">
                  <Skeleton height={300} borderRadius={10}></Skeleton>
                </div>
              </div>
              <div className="row align-items-center mt-5">
                <div className="col-md-6 order-2 order-md-1">
                  <Skeleton height={300} borderRadius={10}></Skeleton>
                </div>
                <div className="col-md-6 order-1 order-md-2">
                  <div className="title-content"><Skeleton height={40} borderRadius={5}></Skeleton></div>
                  <div className="desc-content">
                    <Skeleton height={15} count={5} borderRadius={5}></Skeleton>
                  </div>
                </div>
              </div>
            </>
            :
            <>
              <div className="desc-section mt-4 text-center" dangerouslySetInnerHTML={{ __html: aboutUsContent.desc }}>
              </div>
              <div className="row align-items-center mt-5">
                <div className="col-md-6">
                  <div className="title-content">Visi Kami</div>
                  <div className="desc-content" dangerouslySetInnerHTML={{ __html: aboutUsContent.visi }}>
                  </div>
                </div>
                <div className="col-md-6">
                  <img src={dataUrl.konten(aboutUsContent.visi_img)} alt={aboutUsContent.visi_img} className="w-100 h-auto"></img>
                </div>
              </div>
              <div className="row align-items-center mt-5">
                <div className="col-md-6 order-2 order-md-1">
                  <img src={dataUrl.konten(aboutUsContent.misi_img)} alt={aboutUsContent.misi_img} className="w-100 h-auto"></img>
                </div>
                <div className="col-md-6 order-1 order-md-2">
                  <div className="title-content">Misi Kami</div>
                  <div className="desc-content" dangerouslySetInnerHTML={{ __html: aboutUsContent.misi }}>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
