import React, { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';
import Slider from "react-slick";
import "../../../src/assets/scss/brand/brand.css";
import "../../../src/assets/scss/main/main.css";
import { dataUrl, get_ListDistributor, get_Metadata } from "../../actions";
import CardSkeleton from "../../components/Elements/CardSkeleton";
import CardBrand from "../../components/Elements/Card_Brand";
import LinkRoute from "../../components/Elements/Link/index";
import MetaComponent from "../../components/Elements/MetaData";
import Title from "../../components/Elements/Title";
import { Footer } from "../../components/Layouts/Footer/Footer";
import { Nav } from "../../components/Layouts/Nav";

export function ListDistributor(props) {
  let onFirstLoad = true;
  const [onloadPage, setLoading] = useState(true);
  const [distributorContent, setContent] = useState({
    desc: '',
    list: []
  })
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [metaData, setMeta] = useState(({
    title: '',
    deskripsi: '',
    url: window.location.href,
    image: dataUrl.konten('cb8d1aa6-53c7-4600-ba43-55975d3268f2.png'),
    keywords: 'Cipta rasa jawara, jawara, sembilan, jogja, Yogyakarta, DI Yogyakarta',
  }))

  const getMeta = () => {
    get_Metadata((res) => {
      const {meta} = res
      setMeta(value => ({...value,
        title: 'Distributor Terpercaya | '+ meta.judul_website,
        deskripsi: meta.deskripsi_page,
        image: meta.logo_page,
      }))
    })
  }

  const getData = () => {
    setLoading(true)
    get_ListDistributor((res) => {
      const { distributor_terpercaya, list } = res
      setContent({
        desc: distributor_terpercaya,
        list: list
      })
      setLoading(false)
    })
  }

  useEffect(() => {
    if (onFirstLoad) {
      onFirstLoad = false;
      getData();
      getMeta();
    }
  }, [])

  return (
    <>
      <MetaComponent keywords={metaData.keywords} pageImage={metaData.image} pageTitle={metaData.title} pageDescription={metaData.deskripsi} url={metaData.url}></MetaComponent>
      <Nav active="distributor"></Nav>
      <div className="brand-page">
        <div className="container">
          <Title left="37%">Distributor Terpercaya</Title>
          {onloadPage ?
            <div className="desc-section mt-4 text-center">
              <Skeleton height={15} count={3} borderRadius={5}></Skeleton>
            </div>
            :
            <div className="desc-section mt-4 text-center" dangerouslySetInnerHTML={{ __html: distributorContent.desc }}>
            </div>
          }
          <div className="row mt-5 justify-content-center wrapper-card">
            {onloadPage ?
              <>
                <div className="col-md-4">
                  <CardSkeleton></CardSkeleton>
                </div>
                <div className="col-md-4">
                  <CardSkeleton></CardSkeleton>
                </div>
                <div className="col-md-4">
                  <CardSkeleton></CardSkeleton>
                </div>
              </>
              : <>
                {distributorContent.list.map((item, index) => (
                  <div className="col-md-4" key={`brand${index}`}>
                    <LinkRoute route={`/distributor/${item.short_url}`}>
                      <CardBrand
                        src={dataUrl.distributor(item.logo)}
                        title={item.nama}
                        desc={item.short_deskripsi}
                      ></CardBrand>
                    </LinkRoute>
                  </div>
                ))}
              </>
            }
          </div>

          <div className="slider-wrapper mb-5 mt-5">
            {onloadPage ?
              <div>
                <CardSkeleton></CardSkeleton>
              </div>
              :
              <>
                <Slider {...settings}>
                  {distributorContent.list.map((item, index) => (
                    <div className="" key={`brand${index}`}>
                      <LinkRoute route={`/distributor/${item.short_url}`}>
                        <CardBrand
                          src={dataUrl.distributor(item.logo)}
                          title={item.nama}
                          desc={item.short_deskripsi}
                        ></CardBrand>
                      </LinkRoute>
                    </div>
                  ))}
                </Slider>
              </>
            }
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
