import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { dataUrl } from "../../../actions";
import iconShare from "../../../assets/icon/icon-arrow.svg";
import "../../../assets/scss/home/mitra/mitra.css";
import CardSkeleton from "../../Elements/CardSkeleton/index";
import CardBrand from "../../Elements/Card_Brand/index";
import LinkRoute from "../../Elements/Link";

export function Mitra(props) {
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const { load, content } = props;
  return (
    <div>
      <section className="mitra">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 px-3">
              <div className="title-section">{load ? <Skeleton borderRadius={5} /> : 'Bermitra Dengan'}</div>
              <div className="mt-3">
                {load ?
                  <div>
                    <Skeleton borderRadius={5} height={20} count={3} />
                  </div>
                  :
                  <>
                    <>
                      <div dangerouslySetInnerHTML={{ __html: content.desc }} className="text-justify"></div>
                    </>
                  </>
                }
                {!load ? <>
                  <div className="mb-4 mt-2">
                    <LinkRoute variant="green-color see-more" route="/mitra">
                      Lihat Lebih<img src={iconShare} alt={iconShare}></img>
                    </LinkRoute>
                  </div>
                </>
                  : ''}
              </div>
            </div>
            <div className="col-md-7 ps-4">
              <div className="row justify-content-center wrapper-card">
                {load ?
                  <>
                    <div className="col-lg-6 col-12">
                      <CardSkeleton></CardSkeleton>
                    </div>
                    <div className="col-lg-6 col-12">
                      <CardSkeleton></CardSkeleton>
                    </div>
                  </>
                  :
                  <>
                    {content.list.map((item, index) => (
                      <div className="col-lg-6 col-12" key={`mitra${index}`}>
                        <Link to={`/mitra/${item.short_url}`}>
                          <CardBrand
                            src={dataUrl.mitra(item.logo)}
                            title={item.nama}
                            desc={item.short_deskripsi}
                          ></CardBrand>
                        </Link>
                      </div>
                    ))}
                    {/* <div className="col-lg-6 col-12">
                      <CardBrand
                        src={imgBrand4}
                        title="ISTANBUL KEBAB TURKI"
                        desc="Nikmati nikmatnya cita rasa otentik dari brand kebab Turki kami."
                      ></CardBrand>
                    </div>
                    <div className="col-lg-6 col-12">
                      <CardBrand
                        src={imgBrand5}
                        title="ROCKET CHICKEN"
                        desc="Puaskan diri Anda dengan ayam goreng kami yang renyah dan penuh cita rasa."
                      ></CardBrand>
                    </div> */}
                  </>
                }
              </div>

              <div className="slider-wrapper">
                {load ? <>
                  <div>
                    <CardSkeleton></CardSkeleton>
                  </div>
                </>
                  :
                  <>
                    <Slider {...settings}>
                    {content.list.map((item, index) => (
                      <div key={`mitramobile${index}`}>
                        <Link to={`/mitra/${item.short_url}`}>
                          <CardBrand
                            src={dataUrl.mitra(item.logo)}
                            title={item.nama}
                            desc={item.short_deskripsi}
                          ></CardBrand>
                        </Link>
                      </div>
                    ))}
                    </Slider>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
