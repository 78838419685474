import React from "react";
import imgAsset2 from "../../../assets/img/hero/img-asset-2.png";
import imgAsset from "../../../assets/img/hero/img-asset.png";
import imgHero1 from "../../../assets/img/hero/img-hero-1.png";
import imgHero2 from "../../../assets/img/hero/img-hero-2.png";
import imgHero3 from "../../../assets/img/hero/img-hero-3.png";
import "../../../assets/scss/home/hero/hero.css";
export function Hero() {
  return (
    <>
      <div className="container">
        <div className="hero">
          <div className="title-hero">
            Rasakan Perbedaannya, <br></br>Bergabunglah dalam Perjalanan
          </div>
          <div className="info-hero">
            Cipta Rasa Jawara, Mitra Utama Anda dalam Makanan dan Minuman.
          </div>
          <img
            src={imgAsset}
            alt={imgAsset}
            className="img-square img-square-1"
          ></img>
          <img
            src={imgAsset2}
            alt={imgAsset2}
            className="img-square img-square-2"
          ></img>
          <div className="">
            <div className="d-flex align-items-end justify-content-center wrapper-img-hero">
              <div className="">
                <img src={imgHero1} alt={imgHero1} className="img-hero-1"></img>
              </div>
              <div className="">
                <img src={imgHero2} alt={imgHero2} className="img-hero-2"></img>
              </div>
              <div className="">
                <img src={imgHero3} alt={imgHero3} className="img-hero-3"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
