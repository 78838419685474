import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import "../../../src/assets/scss/home/activity/activity.css";
import "../../../src/assets/scss/main/main.css";
import { dataUrl, get_DetailArtikel } from "../../actions";
import MetaComponent from "../../components/Elements/MetaData";
import { Footer } from "../../components/Layouts/Footer/Footer";
import { Nav } from "../../components/Layouts/Nav";

export function DetailActivity() {
  const { artikel_url } = useParams();
  let firstLoad = true;
  const [onloadPage, setLoading] = useState(true);
  const [activityContent, setContent] = useState({
    title: '',
    isi: '',
    thumbnail: ''
  })

  const [metaData, setMeta] = useState(({
    title: 'Cipta Rasa Jawara',
    deskripsi: '',
    url: window.location.href,
    image: dataUrl.konten('cb8d1aa6-53c7-4600-ba43-55975d3268f2.png'),
    keywords: 'Cipta rasa jawara, jawara, sembilan, jogja, Yogyakarta, DI Yogyakarta',
  }))


  const getData = () => {
    setLoading(true)

    get_DetailArtikel(artikel_url, (res) => {
      const { data } = res
      setContent({
        title: data.judul,
        isi: data.isi,
        thumbnail: data.thumbnail
      })
      setMeta(value => ({ ...value, 
        title: data.judul + ' | Cipta Rasa Jawara', 
        deskripsi: data.isi, 
        image: dataUrl.konten(data.thumbnail) 
      }));
      setLoading(false);
    })
  }

  useEffect(() => {
    if (firstLoad) {
      firstLoad = true;
      getData();
    }
  }, [])
  return (
    <>
      <MetaComponent keywords={metaData.keywords} pageImage={metaData.image} pageTitle={metaData.title} pageDescription={metaData.deskripsi} url={metaData.url}></MetaComponent>
      <Nav active="home"></Nav>
      <div className="activity-page detail-acivity">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="item-activity">
                <div className="title-item mt-4">{onloadPage ? <Skeleton height={45} borderRadius={5} /> : activityContent.title}</div>
                {onloadPage ?
                  <>
                    <div className="mt-4"></div>
                    <div className="desc-item">
                      <Skeleton height={15} borderRadius={5} count={10}></Skeleton>
                    </div>
                  </>
                  : <>
                    <div className="desc-item" dangerouslySetInnerHTML={{ __html: activityContent.isi }}>

                    </div>
                  </>
                }
              </div>
            </div>
            <div className="col-md-6 col-12">
              {onloadPage ? <Skeleton height={330} borderRadius={5}></Skeleton> : <img src={dataUrl.konten(activityContent.thumbnail)} alt={dataUrl.konten(activityContent.thumbnail)}></img>}
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
