import React, { useEffect, useState } from "react";
import "../../../src/assets/scss/main/main.css";
import { dataUrl, get_ListCareer, get_Metadata } from "../../actions";
import SkeletonCareer from "../../components/Elements/CardSkeleton/career";
import CardCarer from "../../components/Elements/Career";
import Title from "../../components/Elements/Title";
import { Footer } from "../../components/Layouts/Footer/Footer";
import { Nav } from "../../components/Layouts/Nav";

import 'moment/locale/id';
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/Elements/MetaData";
export function Career() {
  let onFirstLoad = true;
  const [onloadPage, setLoading] = useState(true);
  const [careerContent, setContent] = useState({
    desc: '',
    list: []
  })

  const [metaData, setMeta] = useState(({
    title: '',
    deskripsi: '',
    url: window.location.href,
    image: dataUrl.konten('cb8d1aa6-53c7-4600-ba43-55975d3268f2.png'),
    keywords: 'Cipta rasa jawara, jawara, sembilan, jogja, Yogyakarta, DI Yogyakarta',
  }))

  const getMeta = () => {
    get_Metadata((res) => {
      const {meta} = res
      setMeta(value => ({...value,
        title: 'Karir | '+ meta.judul_website,
        deskripsi: meta.deskripsi_page,
        image: meta.logo_page,
      }))
    })
  }

  const getData = () => {
    setLoading(true)
    get_ListCareer((res) => {
      const { karir, list } = res
      setContent({
        desc: karir,
        list: list
      })
      setLoading(false)
    })
  }

  useEffect(() => {
    if (onFirstLoad) {
      onFirstLoad = false;
      getData();
      getMeta();
    }
  }, [])
  return (
    <>
      <MetaComponent keywords={metaData.keywords} pageImage={metaData.image} pageTitle={metaData.title} pageDescription={metaData.deskripsi} url={metaData.url}></MetaComponent>
      <Nav active="career"></Nav>
      <div className="career">
        <div className="container">
          <Title left="45%">Karir</Title>
          {onloadPage ?
            <div className="desc-section mt-4 text-center">
              <Skeleton height={15} count={3} borderRadius={5}></Skeleton>
            </div>
            :
            <div className="desc-section mt-4 text-center" dangerouslySetInnerHTML={{ __html: careerContent.desc }}>
            </div>
          }
          <div className="list-career mt-5">
            <div className="row">
              {onloadPage ?
                <>
                  <div className="col-md-4">
                    <SkeletonCareer></SkeletonCareer>
                  </div>
                  <div className="col-md-4">
                    <SkeletonCareer></SkeletonCareer>
                  </div>
                  <div className="col-md-4">
                    <SkeletonCareer></SkeletonCareer>
                  </div>
                </>
                : <>
                  {careerContent.list.map((item, index) => (
                    <div className="col-md-4 col-12" key={`career${index}`}>
                      <Link style={{ color: 'unset' }} to={`/career/${item.short_url}`}>
                        <CardCarer
                          title={item.title}
                          banner={item.banner}
                          deadline={moment(item.deadline).format('DD MMMM YYYY')}
                          desc={item.deskripsi}
                          short_url={item.short_url}
                        ></CardCarer>
                      </Link>
                    </div>
                  ))}
                </>
              }
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
