import Skeleton from "react-loading-skeleton";

const SkeletonProduct = (props) => {
  const { name, desc, img } = props;
  return (
    <div className="product-card">
      <div className="product-img">
        <Skeleton height={235} borderRadius={10} />
      </div>
      <div className="px-3 p-3">
        <div className="product-name"><Skeleton height={20}></Skeleton></div>
        <div className="product-desc"><Skeleton height={10} count={2}></Skeleton></div>
      </div>
    </div>
  );
};

export default SkeletonProduct;
