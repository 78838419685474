import React from "react";
import Skeleton from 'react-loading-skeleton';
import iconShare from "../../../assets/icon/icon-arrow.svg";
import BgCardMission from "../../../assets/img/mission/bg-card-mission.svg";
import imgMission from "../../../assets/img/mission/img-mission.png";
import "../../../assets/scss/home/mission/mission.css";
import LinkRoute from "../../Elements/Link";

export function Mission(props) {
  const { load, content } = props;
  return (
    <div>
      <section className="mission">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="title-section"> {load ? <Skeleton borderRadius={5} /> : <>Tentang Kami <br></br> dan Misi Kami</>}</div>
            </div>
            <div className="col-md-8 position-relative">
              {load ?
                <div>
                  <Skeleton borderRadius={5} height={20} count={3} />
                </div>
                :
                <>
                <div dangerouslySetInnerHTML={{ __html: content.desc }} className="text-justify"></div>
                </>}
              {!load ? <>
                <div className="text-end mt-2">
                  <LinkRoute variant="green-color see-more" style={{ position: 'relative' }} route="/about-us">
                    Lihat Lebih <img src={iconShare} alt={iconShare}></img>
                  </LinkRoute>
                </div>
              </> : ''}
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-8">
              <img
                src={imgMission}
                alt={imgMission}
                className="img-mission"
              ></img>
            </div>
            <div className="col-md-4">
              <div className="card-mission">
                <img
                  src={BgCardMission}
                  alt={BgCardMission}
                  className="bg-card-mission"
                ></img>
                <div className="text-card">
                  Rasakan Perbedaannya, Bergabunglah dalam Perjalanan
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
