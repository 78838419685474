import "../../../assets/scss/main/main.css";

const Product = (props) => {
  const { name, desc, img } = props;
  return (
    <div className="product-card">
      <div className="product-img">
        <img src={img} alt={img}></img>
      </div>
      <div className="px-3 p-3">
        <div className="product-name">{name}</div>
        <div className="product-desc">{desc}</div>
      </div>
    </div>
  );
};

export default Product;
