import React from "react";
import Skeleton from "react-loading-skeleton";
import URLParse from "url-parse";
import "../../../assets/scss/home/video_embed/video_embed.css";
export function VideoEmbed(props) {
  const {load, url} = props
  const videoId = URLParse(url, true).query.v;
  console.log(videoId, url)
  return (
    <div className="video-responsive" style={{ paddingBottom: load ? 'unset' : '' }}>
        {load ? 
        <Skeleton style={{ width: '100%', height: '100%' }} height={500}></Skeleton>
        :
        <section class="video">
        <iframe
          // width="100%"
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </section>
}
    </div>
  );
}
