import React from "react";
import Slider from "react-slick";
import "../../../assets/scss/home/brand/brand.css";

import iconShare from "../../../assets/icon/icon-arrow.svg";
import CardBrand from "../../Elements/Card_Brand/index";
import LinkRoute from "../../Elements/Link";

import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { dataUrl } from "../../../actions";
import CardSkeleton from "../../Elements/CardSkeleton/index";

export function Brand(props) {
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    padding : 20,
  };
  const { load, content } = props;
  return (
    <div>
      <section className="brand">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="title-section">{load ? <Skeleton borderRadius={5} /> : 'Brand Kami'}</div>
            </div>
            <div className="col-md-8 position-relative">
              {load ?
                <div>
                  <Skeleton borderRadius={5} height={20} count={3} />
                </div>
                :
                <>
                  <div dangerouslySetInnerHTML={{ __html: content.desc }} className="text-justify"></div>
                </>
              }
              {!load ? <>
                <div className="mb-4 mt-2">
                  <LinkRoute variant="green-color see-more" style={{ position: 'relative' }} route="/brand">
                    Lihat Lebih <img src={iconShare} alt={iconShare}></img>
                  </LinkRoute>
                </div>
              </> : ''}

            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row mt-5 wrapper-card">
            {load ?
              <>
                <div className="col-md-4">
                  <CardSkeleton></CardSkeleton>
                </div>
                <div className="col-md-4">
                  <CardSkeleton></CardSkeleton>
                </div>
                <div className="col-md-4">
                  <CardSkeleton></CardSkeleton>
                </div>
              </>
              :
              <>
                {content.list.map((item, index) => (
                  <div className="col-md-4" key={`brand${index}`}>
                    <Link to={`/brand/${item.short_url}`}>
                      <CardBrand
                        src={dataUrl.brand(item.logo)}
                        title={item.nama}
                        desc={item.short_deskripsi}
                      ></CardBrand>
                    </Link>
                  </div>
                ))}
              </>
            }
          </div>
          <div className="slider-wrapper">
            {load ? <>
              <div>
                <CardSkeleton></CardSkeleton>
              </div>
            </>
              :
              <>
                <Slider {...settings}>
                  {content.list.map((item, index) => (
                    <div key={`mobilebrand${index}`}>
                      <Link to={`/brand/${item.short_url}`}>
                        <CardBrand
                          src={dataUrl.brand(item.logo)}
                          title={item.nama}
                          desc={item.short_deskripsi}
                        ></CardBrand>
                      </Link>
                    </div>
                  ))}
                </Slider>
              </>
            }
          </div>
        </div>
      </section>
    </div>
  );
}
