import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "../../../src/assets/scss/brand/detail/index.css";
import "../../../src/assets/scss/main/main.css";
import CardBrand from "../../components/Elements/Card_Brand";
import ItemMaps from "../../components/Elements/Maps";
import Title from "../../components/Elements/Title";
import { Footer } from "../../components/Layouts/Footer/Footer";
import { Nav } from "../../components/Layouts/Nav";

import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router";
import { dataUrl, get_DetailDistributor } from "../../actions";
import facebook from "../../assets/icon/facebook.svg";
import instagram from "../../assets/icon/instagram.svg";
import twitter from "../../assets/icon/twitter.svg";
import youtube from "../../assets/icon/youtube.svg";
import SkeletonBrand from "../../components/Elements/CardSkeleton/brand";
import SkeletonLokasi from "../../components/Elements/CardSkeleton/maps";
import MetaComponent from "../../components/Elements/MetaData";


export function Distributor() {
  const { distributor_url } = useParams();
  let firstLoad = true;
  const [onloadPage, setLoading] = useState(true);
  const [distributorContent, setContent] = useState({
    nama: '',
    logo: '',
    profile: '',
    brand: '',
    facebook_url: '',
    instagram_url: '',
    youtube_url: '',
    twitter_url: '',
    outlet: [],
    produk: [],
  })
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  
  function Mapp(props) {
    const { dataLokasi } = props
    const containerStyle = {
      width: "100%",
      height: "60vh",
    };

    var koordinatFirst = dataLokasi.length > 0 ? dataLokasi[0].koordinat.split(',') : [-6.175072402611033, 106.82723863065007];
    const center = { 
      lat: parseFloat(koordinatFirst[0]),
      lng: parseFloat(koordinatFirst[1]),
    };
    const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: "AIzaSyCVcBFOy1d-5h_VLjgIRZWYvz4Kxa2W1W4",
    });
    return isLoaded ? (
      <GoogleMap mapContainerStyle={containerStyle} zoom={14} center={center}>
        {dataLokasi.map((item) => {
          var split = item.koordinat.split(',');
          var coordinate = {
            lat: parseFloat(split[0]),
            lng: parseFloat(split[1])
          }
          return <Marker position={coordinate} key={item.id} />;
        })}
      </GoogleMap>
    ) : '';
  }

  const [metaData, setMeta] = useState(({
    title: '',
    deskripsi: '',
    url: window.location.href,
    image: dataUrl.konten('cb8d1aa6-53c7-4600-ba43-55975d3268f2.png'),
    keywords: 'Cipta rasa jawara, jawara, sembilan, jogja, Yogyakarta, DI Yogyakarta',
  }))

  const getData = () => {
    setLoading(true)
    get_DetailDistributor(distributor_url, (res) => {
      const { data, produk, werehouse } = res
      setContent({
        nama: data.nama,
        logo: data.logo,
        profile: data.profile,
        brand: data.brand,
        facebook_url: data.facebook_url,
        twitter_url: data.twitter_url,
        youtube_url: data.youtube_url,
        instagram_url: data.instagram_url,
        produk: produk,
        outlet: werehouse
      })

      setMeta(value => ({ ...value, 
        title: 'Distributor Terpercaya '+data.nama+ ' | Cipta Rasa Jawara', 
        deskripsi: data.profile, 
        image: dataUrl.distributor(data.logo) 
      }));

      setLoading(false);
    })
  }

  useEffect(() => {
    if (firstLoad) {
      firstLoad = false;
      getData();
    }
  }, [])

  return (
    <>
      <MetaComponent keywords={metaData.keywords} pageImage={metaData.image} pageTitle={metaData.title} pageDescription={metaData.deskripsi} url={metaData.url}></MetaComponent>
      <Nav active="distributor"></Nav>
      <div className="detail-brand">
        <div className="container">
          <Title left="27%">
            {onloadPage ? <Skeleton width={200} height={50} borderRadius={5}></Skeleton> : <>Distributor <span>Terpercaya</span> {distributorContent.nama}</>}
          </Title>
          <div className="row mt-5">
            <div className="col-md-2 col-12 logo-distributor">
              {onloadPage ? <Skeleton width={200} height={200} borderRadius={5}></Skeleton> : <CardBrand src={dataUrl.distributor(distributorContent.logo)}></CardBrand>}
              {!onloadPage ?
                <div className="sosmed mt-4">
                  <ul style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                    {
                      distributorContent.facebook_url ?
                        <li className="mb-2">
                          <a target="_blank" href={distributorContent.facebook_url}>
                            <img src={facebook} alt={facebook}></img>
                          </a>
                        </li>
                        : ''
                    }

                    {
                      distributorContent.twitter_url ?
                        <li className="mb-2">
                          <a target="_blank" href={distributorContent.twitter_url}>
                            <img src={twitter} alt={twitter}></img>
                          </a>
                        </li>
                        : ''
                    }
                    {
                      distributorContent.instagram_url ?
                        <li className="mb-2">
                          <a target="_blank" href={distributorContent.instagram_url}>
                            <img src={instagram} alt={instagram}></img>
                          </a>
                        </li>
                        : ''
                    }
                    {
                      distributorContent.youtube_url ?
                        <li className="mb-2">
                          <a target="_blank" href={distributorContent.youtube_url}>
                            <img src={youtube} alt={youtube}></img>
                          </a>
                        </li>
                        : ''
                    }
                  </ul>
                </div>
                : ''}
            </div>
            <div className="col-md-10 col-12 ps-4">
              <div className="content">
                <div className="title-content">{onloadPage ? <Skeleton width={150} height={40} borderRadius={5}></Skeleton> : 'Profil'}</div>
                {onloadPage ? <Skeleton height={20} count={5} borderRadius={5}></Skeleton> :
                  <div className="desc-content text-justify mb-3">
                    {distributorContent.profile}
                  </div>
                }
              </div>
              <div className="content">
                <div className="title-content">{onloadPage ? <Skeleton width={150} height={40} borderRadius={5}></Skeleton> : 'Brand'}</div>
                {onloadPage ? <Skeleton height={20} count={5} borderRadius={5}></Skeleton> :
                  <div className="desc-content text-justify mb-3">
                    {distributorContent.brand}
                  </div>
                }
              </div>
              {distributorContent.produk.length > 0 || (distributorContent.produk.length == 0 && onloadPage) ?
                <>
                  <div className="content">
                    <div className="list-brand mt-5 wrapper-card">
                      {onloadPage ?
                        <>
                          <div className="item-brand">
                            <SkeletonBrand></SkeletonBrand>
                          </div>
                          <div className="item-brand">
                            <SkeletonBrand></SkeletonBrand>
                          </div>
                          <div className="item-brand">
                            <SkeletonBrand></SkeletonBrand>
                          </div>
                          <div className="item-brand">
                            <SkeletonBrand></SkeletonBrand>
                          </div>
                          <div className="item-brand">
                            <SkeletonBrand></SkeletonBrand>
                          </div>
                        </>
                        :
                        <>
                          {distributorContent.produk.map((item, index) => (
                            <div className="item-brand" key={`brand${index}`}>
                              <CardBrand
                                src={dataUrl.distributor(item.foto)}
                                title={item.nama}
                              ></CardBrand>
                            </div>
                          ))}
                        </>
                      }

                    </div>
                  </div>
                </> : ''}
              {distributorContent.produk.length > 0 || (distributorContent.produk.length == 0 && onloadPage) ?
                <>
                  <div className="slider-wrapper mb-5">
                    {onloadPage ?
                      <>
                        <div className="item-brand">
                          <SkeletonBrand></SkeletonBrand>
                        </div>
                        <div className="item-brand">
                          <SkeletonBrand></SkeletonBrand>
                        </div>
                        <div className="item-brand">
                          <SkeletonBrand></SkeletonBrand>
                        </div>
                      </>
                      :
                      <>
                        <Slider {...settings}>
                          {distributorContent.produk.map((item, index) => (
                            <div className="item-brand" key={`mbrand${index}`}>
                              <CardBrand
                                src={dataUrl.distributor(item.foto)}
                                title={item.nama}
                              ></CardBrand>
                            </div>
                          ))}
                        </Slider>
                      </>
                    }
                  </div>
                </> : ''}

              {distributorContent.outlet.length > 0 || (distributorContent.outlet.length == 0 && onloadPage) ?
                <>
                  <div className="content">
                    <div className="title-content mb-3">{onloadPage ? <Skeleton width={200} height={40} borderRadius={5}></Skeleton> : 'Lokasi'}</div>
                    <div className="card wrapper-map">
                    {onloadPage ? <Skeleton height={'60vh'} borderRadius={10}></Skeleton> : <Mapp dataLokasi={distributorContent.outlet} /> }
                    </div>
                    <div className="list-maps mt-5">
                      <div className="row">
                        {onloadPage ?
                          <>
                            <div className="col-md-6">
                              <SkeletonLokasi></SkeletonLokasi>
                            </div>
                            <div className="col-md-6">
                              <SkeletonLokasi></SkeletonLokasi>
                            </div>
                          </>
                          :
                          <>
                            {distributorContent.outlet.map((item, index) => (
                              <div className="col-md-6">
                                <ItemMaps
                                  title={item.nama}
                                  address={item.alamat_full}
                                  telp={item.no_hp ? item.no_hp : ''}
                                ></ItemMaps>
                              </div>
                            ))}
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </>
                : ''
              }
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
