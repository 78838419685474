import React, { useEffect, useState } from "react";
import { get_SocialData } from "../../../actions";
import facebook from "../../../assets/icon/facebook.svg";
import iconBrand from "../../../assets/icon/icon-brand-white.png";
import instagram from "../../../assets/icon/instagram.svg";
import twitter from "../../../assets/icon/twitter.svg";
import youtube from "../../../assets/icon/youtube.svg";
import bg from "../../../assets/img/bg-footer.png";
import "../../../assets/scss/footer/footer.css";
import LinkRoute from "../../Elements/Link";
export function Footer() {
  let firstLoad = true;
  const [socialMedia, setSocial] = useState({
    facebook: '',
    instagram: '',
    youtube: '',
    twitter: '',
  })

  const getData = () => {
    get_SocialData((res) => {
      const { meta } = res
      setSocial({
        facebook: meta.facebook_url,
        instagram: meta.instagram_url,
        twitter: meta.twitter_url,
        youtube: meta.youtube_url,
      })
    })
  }

  useEffect(() => {
    if (firstLoad) {
      firstLoad = false;
      getData();
    }
  }, [])

  return (
    <div>
      <footer className="footer">
        <img src={bg} alt={bg} className="bg-footer"></img>
        <div className="wrapper-footer">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4 col-12">
                <img src={iconBrand} alt={bg} className="icon-brand"></img>
              </div>
              <div className="col-md-8 col-12 text-start text-md-end ">
                <ul className="footer-nav">
                  <li>
                    <LinkRoute route="/">Home</LinkRoute>
                  </li>
                  <li>
                    <LinkRoute route="/brand">Brand</LinkRoute>
                  </li>
                  <li>
                    <LinkRoute variant="nav-link " route="/mitra">
                      Bermitra
                    </LinkRoute>
                  </li>
                  <li>
                    <LinkRoute route="/distributor">Distributor</LinkRoute>
                  </li>
                  <li>
                    <LinkRoute route="/career">Karir</LinkRoute>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row align-items-center mt-5">
              <div className="col-12 col-md-4">
                <div className="copyright">©️ 2023 CRJ All rights reserved</div>
              </div>
              <div className="col-md-8 col-12 text-start text-md-end">
                <ul className="">
                  {socialMedia.facebook ?
                    <li>
                      <a target="_blank" href={socialMedia.facebook}>
                        <img src={facebook} alt={facebook}></img>
                      </a>
                    </li>
                    : ''}

                  {socialMedia.youtube ?
                    <li>
                      <a target="_blank" href={socialMedia.youtube}>
                        <img src={youtube} alt={youtube}></img>
                      </a>
                    </li>
                    : ''}

                  {socialMedia.twitter ?
                    <li>
                      <a target="_blank" href={socialMedia.twitter}>
                        <img src={twitter} alt={twitter}></img>
                      </a>
                    </li>
                    : ''}

                  {socialMedia.instagram ?
                    <li>
                      <a target="_blank" href={socialMedia.instagram}>
                        <img src={instagram} alt={instagram}></img>
                      </a>
                    </li>
                    : ''}


                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
