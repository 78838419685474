import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const MetaComponent = ({ pageTitle, pageDescription, keywords, url, pageImage }) => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name='url' content={url}></meta>
          <meta name="description" content={pageDescription} />
          <meta name="title" content={pageTitle} />
          <meta name="description" content={pageDescription} />
          <meta name="keywords" content={keywords} />
          <meta name="robots" content="index, follow" />
          <meta http-equiv="refresh" content="##; url=wherever"></meta>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name='og:title' content={pageTitle} />
          <meta name='og:type' content='article' />
          <meta name='og:url' content={url} />
          <meta name='og:image' content={pageImage} />
          <meta name='og:site_name' content="Cipta Rasa Jawara" />
          <meta name='og:description' content={pageDescription} />
          <meta name="twitter:title" content={pageTitle}></meta>
          <meta name="twitter:image" content={pageImage}></meta>
          <meta name="twitter:image" content={pageImage}></meta>
          <meta name="twitter:description" content={pageDescription}></meta>

        </Helmet>
      </HelmetProvider>
      {/* Your component content goes here */}
    </>
  );
};

export default MetaComponent;