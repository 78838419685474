import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../src/assets/scss/brand/detail/index.css";
import "../../src/assets/scss/contact-us/contact-us.css";
import "../../src/assets/scss/main/main.css";
import { dataUrl, get_DataKontak, get_Metadata, post_StoreKontak } from "../actions";
import imgSuccess from "../assets/img/img-success-contact.svg";
import SkeletonLokasi from "../components/Elements/CardSkeleton/maps";
import ItemMaps from "../components/Elements/Maps";
import MetaComponent from "../components/Elements/MetaData";
import Title from "../components/Elements/Title";
import { Footer } from "../components/Layouts/Footer/Footer";
import { Nav } from "../components/Layouts/Nav";
import { showAlert } from "../helper";

export function ContactUs() {
  const swalContact = withReactContent(Swal);
  let firstLoad = true;
  const [onloadPage, setLoading] = useState(true);
  const [firstLoadData, setFirst] = useState(true);
  const [onSubmit, setSubmit] = useState(false);
  const [obj, setInput] = useState({});
  const [contactContent, setContent] = useState({
    desc: '',
    title: '',
    address: '',
    telp: '',
    koordinat: {
      lat: 0,
      lng: 0
    }
  })
  const [metaData, setMeta] = useState(({
    title: '',
    deskripsi: '',
    url: window.location.href,
    image: dataUrl.konten('cb8d1aa6-53c7-4600-ba43-55975d3268f2.png'),
    keywords: 'Cipta rasa jawara, jawara, sembilan, jogja, Yogyakarta, DI Yogyakarta',
  }))

  const getMeta = () => {
    get_Metadata((res) => {
      const {meta} = res
      setMeta(value => ({...value,
        title: 'Kontak Kami | '+ meta.judul_website,
        deskripsi: meta.deskripsi_page,
        image: meta.logo_page,
      }))
    })
  }

  const clickSumbit = (e) => {
    e.preventDefault();

    if (obj.nama && obj.no_hp && obj.email && obj.pesan && obj.alamat) {
      setSubmit(true);
      post_StoreKontak(obj, () => {
        setSubmit(false)
        setInput({});
        swalContact.fire({
          html: `
          <div><img src=${imgSuccess} style="width: 80px;"></img></div>
          <div class="mt-3 text">
            <b style="font-size: 16px; margin-bottom:10px;">Pesan Anda berhasil terkirim</b>
            <p style="font-size: 14px;margin-top: 10px;">Terima kasih telah mengirimkan pesan kepada kami! Tim kami akan segera merespon Anda.</p>
          </div>`,
          confirmButtonText: "Tutup",
          background: "#fff",
          color: "#2B2F33",
          padding: "2em",
          confirmButtonColor: "#00753E",
          customClass: {
            confirmButton: "button-alert",
          },
        });
      }, () => {
        showAlert(swalContact, {
          type: 'danger',
          msg: 'Terjadi kesalahan dalam menyimpan data, silakan ulangi kembali nanti',
          buttonText: 'Tutup'
        })
      })
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInput(values => ({ ...values, [name]: value }))
  }

  const getData = () => {
    setLoading(true)

    get_DataKontak((res) => {
      const { content, data_page } = res
      var split = data_page.koordinat.split(',')
      setContent({
        desc: content,
        title: data_page.lokasi,
        address: data_page.alamat,
        telp: data_page.no_telp,
        koordinat: {
          lat: parseFloat(split[0]),
          lng: parseFloat(split[1]),
        }
      })

      setLoading(false);

      setTimeout(() => {
        setFirst(false);
      }, 1000);
    })
  }

  useEffect(() => {
    if (firstLoad) {
      firstLoad = true;
      getData();
      getMeta();
    }
  }, [])

  return (
    <>
      <MetaComponent keywords={metaData.keywords} pageImage={metaData.image} pageTitle={metaData.title} pageDescription={metaData.deskripsi} url={metaData.url}></MetaComponent>
      <Nav></Nav>
      <div className="detail-brand" id="contact-us">
        <div className="container">
          <Title>Kontak Kami</Title>
          {onloadPage ?
            <div className="desc-section mt-4 text-center">
              <Skeleton height={15} count={3} borderRadius={5}></Skeleton>
            </div>
            :
            <div className="desc-section mt-4 text-center" dangerouslySetInnerHTML={{ __html: contactContent.desc }}>
            </div>
          }
          <div className="row mt-5 align-items-center">
            <div className="col-md-6 col-12">
              <div className="card">
                {onloadPage ? <Skeleton height={'40vh'} borderRadius={5}></Skeleton> : <iframe width="100%" style={{
                  width: "100%",
                  height: "40vh",
                }} src={`https://maps.google.com/maps?q=${contactContent.koordinat.lat},${contactContent.koordinat.lng}&hl=es;z=14&output=embed`} action="embedview" loading="lazy" frameborder="0" allowfullscreen="" referrerpolicy="no-referrer-when-downgrade"></iframe>}
                <div className="list-maps mt-4">
                  {onloadPage
                    ?
                    <SkeletonLokasi></SkeletonLokasi>
                    :
                    <ItemMaps
                      title={contactContent.title}
                      address={contactContent.address}
                      telp={contactContent.telp}
                    ></ItemMaps>
                  }
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <form className="form-contact" onSubmit={clickSumbit}>
                <div className="mb-4">
                  <label className="form-label">Nama <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Masukkan Nama Anda"
                    name="nama"
                    value={obj.nama ?? ""}
                    onChange={e => handleChange(e)}
                    required
                  ></input>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="mb-4">
                      <label className="form-label">Nomor Telepon <span className="text-danger">*</span></label>
                      <ReactInputMask mask="999999999999" required={true} value={obj.no_hp} maskChar="" className="form-control" placeholder="Masukkan No Telepon Anda" name="no_hp" onChange={e => handleChange(e)}>
                      </ReactInputMask>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="mb-4">
                      <label className="form-label">Email <span className="text-danger">*</span></label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Masukkan Email Anda"
                        name="email"
                        value={obj.email ?? ""}
                        onChange={e => handleChange(e)}
                        required
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <label className="form-label">Alamat <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Masukkan Alamat Anda"
                    name="alamat"
                    value={obj.alamat ?? ""}
                    onChange={e => handleChange(e)}
                    required
                  ></input>
                </div>

                <div className="mb-4">
                  <label className="form-label">Pesan <span className="text-danger">*</span></label>
                  <textarea
                    className="form-control"
                    placeholder="Pesan"
                    name="pesan"
                    value={obj.pesan ?? ""}
                    onChange={e => handleChange(e)}
                    required
                  ></textarea>
                </div>

                <button
                  className="btn btn-green"
                  type="submit"
                  disabled={onSubmit}
                >
                  {onSubmit ? <FontAwesomeIcon icon={faSpinner} spin={true}></FontAwesomeIcon> : 'Kirim Pesan'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
