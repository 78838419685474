import "../../../assets/scss/main/main.css";
import iconMaps from "../../../../src/assets/icon/icon-maps.svg";
const Title = (props) => {
  const { title, address, telp } = props;
  return (
    <div className="item-maps d-flex">
      <div>
        <img src={iconMaps} alt={iconMaps}></img>
      </div>
      <div className="ms-3">
        <div className="title-maps">{title}</div>
        <div className="address">{address}</div>
        <div className="telp">{telp ? `Telp : ${telp}` : ''}</div>
      </div>
    </div>
  );
};

export default Title;
