import React from "react";
import iconShare from "../../../assets/icon/icon-arrow.svg";
import "../../../assets/scss/home/activity/activity.css";
import LinkRoute from "../../Elements/Link";

import Skeleton from "react-loading-skeleton";
import { dataUrl } from "../../../actions";

export function Activity(props) {
  const { load, content } = props;
  return (
    <div>
      <section className="activity">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="title-section">{load ? <Skeleton borderRadius={5} /> : 'Aktivitas Kami'}</div>
            </div>
            <div className="col-md-8">
              {load ?
                <div>
                  <Skeleton borderRadius={5} height={20} count={3} />
                </div>
                :
                <>
                  <div dangerouslySetInnerHTML={{ __html: content.desc }} className="text-justify"></div>
                </>
              }
              {!load ? <>
                <div className="text-end mt-2">
                  <LinkRoute route="/aktivitas" style={{ position: 'relative' }} variant="green-color see-more">
                    Lihat Lebih <img src={iconShare} alt={iconShare}></img>
                  </LinkRoute>
                </div>
              </> : ''}
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="list-activity" style={{
            gridTemplateRows: load ? 'unset' : ''
          }}>
            {load ? <>
              <div className="item-activity item-activity-1 ">
                <Skeleton borderRadius={5} style={{ width: '100%' }} height={500}></Skeleton>
                <div className="title-item mt-4"><Skeleton borderRadius={5} height={40}></Skeleton></div>
                <div className="desc-item">
                  <Skeleton borderRadius={5} height={20} count={3}></Skeleton>
                </div>
              </div>
              <div className="item-activity item-activity-2 item-right">
                <div className="d-flex">
                  <Skeleton borderRadius={10} style={{ width: '255px' }} height={225}></Skeleton>
                  <div>
                    <div className="title-item ms-3">
                      <Skeleton borderRadius={5} width={300} height={40}></Skeleton>
                    </div>
                    <div className="desc-item ms-3">
                      <Skeleton borderRadius={5} width={300} height={20} count={3}></Skeleton>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-activity item-activity-3 item-right">
                <div className="d-flex">
                  <Skeleton borderRadius={10} style={{ width: '255px' }} height={225}></Skeleton>
                  <div>
                    <div className="title-item ms-3">
                      <Skeleton borderRadius={5} width={300} height={40}></Skeleton>
                    </div>
                    <div className="desc-item ms-3">
                      <Skeleton borderRadius={5} width={300} height={20} count={3}></Skeleton>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-activity item-activity-4 item-right">
                <div className="d-flex">
                  <Skeleton borderRadius={10} style={{ width: '255px' }} height={225}></Skeleton>
                  <div>
                    <div className="title-item ms-3">
                      <Skeleton borderRadius={5} width={300} height={40}></Skeleton>
                    </div>
                    <div className="desc-item ms-3">
                      <Skeleton borderRadius={5} width={300} height={20} count={3}></Skeleton>
                    </div>
                  </div>
                </div>
              </div>
            </>
              :
              <>
                {content.list.map((item, index) => (
                  <>
                    {(index > 0) ?
                      <>
                        <div className={`item-activity item-activity-${index + 1} item-right`}>
                          <LinkRoute route={`/aktivitas/${item.short_url}`}>
                            <div className="d-flex flex-wrap">
                              <img src={dataUrl.konten(item.thumbnail)} alt={item.thumbnail}></img>
                              <div className="content-text">
                                <div className="title-item">
                                  {item.judul}
                                </div>
                                <div className="desc-item ms-3" dangerouslySetInnerHTML={{ __html: item.isi }}>
                                </div>
                              </div>
                            </div>
                          </LinkRoute>
                        </div>
                      </>
                      : <>
                        <div className={`item-activity item-activity-1`}>
                          <LinkRoute route={`/aktivitas/${item.short_url}`}>

                            <img src={dataUrl.konten(item.thumbnail)} alt={item.thumbnail}></img>
                            <div className="title-item mt-4">{item.judul}</div>
                            <div className="desc-item" dangerouslySetInnerHTML={{ __html: item.isi }}>
                            </div>
                          </LinkRoute>
                        </div>
                      </>
                    }
                  </>
                ))}

                {/* <div className="item-activity item-activity-2 item-right">
                  <LinkRoute route="/aktivitas/detail">
                    <div className="d-flex">
                      <img src={img2} alt={img2}></img>
                      <div>
                        <div className="title-item ms-3">
                          Behind The Scene Web Series “Atik”
                        </div>
                        <div className="desc-item ms-3">
                          Awal januari kemarin kita berkesempatan untuk melaksanakan
                          RUPS sebagai agenda untuk menyampaikan apa yang sudah Awal
                          januari kemarin kita berkesempatan untuk melaksanakan RUPS
                          sebagai agenda untuk menyampaikan apa yang sudah
                        </div>
                      </div>
                    </div>
                  </LinkRoute>
                </div>
                <div className="item-activity item-activity-3 item-right">
                  <LinkRoute route="/aktivitas/detail">
                    <div className="d-flex">
                      <img src={img2} alt={img2}></img>
                      <div>
                        <div className="title-item ms-3">
                          Behind The Scene Web Series “Atik”
                        </div>
                        <div className="desc-item ms-3">
                          Awal januari kemarin kita berkesempatan untuk melaksanakan
                          RUPS sebagai agenda untuk menyampaikan apa yang sudah Awal
                          januari kemarin kita berkesempatan untuk melaksanakan RUPS
                          sebagai agenda untuk menyampaikan apa yang sudah
                        </div>
                      </div>
                    </div>
                  </LinkRoute>
                </div>
                <div className="item-activity item-activity-4 item-right">
                  <LinkRoute route="/aktivitas/detail">
                    <div className="d-flex">
                      <img src={img2} alt={img2}></img>
                      <div>
                        <div className="title-item ms-3">
                          Behind The Scene Web Series “Atik”
                        </div>
                        <div className="desc-item ms-3">
                          Awal januari kemarin kita berkesempatan untuk melaksanakan
                          RUPS sebagai agenda untuk menyampaikan apa yang sudah Awal
                          januari kemarin kita berkesempatan untuk melaksanakan RUPS
                          sebagai agenda untuk menyampaikan apa yang sudah
                        </div>
                      </div>
                    </div>
                  </LinkRoute>
                </div> */}
              </>
            }
          </div>
        </div>
      </section>
    </div>
  );
}
