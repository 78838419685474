import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import 'moment/locale/id';
import React, { useEffect, useRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useParams } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../../src/assets/scss/main/main.css";
import { dataUrl, get_DetailCareer, get_KabupatenList, get_KecamatanList, get_KelurahanList, get_ProvinsiList, post_StoreCareer } from "../../actions";
import imgSuccess from "../../assets/img/img-suscess-apply.svg";
import SkeletonCareer from "../../components/Elements/CardSkeleton/career";
import CardCarer from "../../components/Elements/Career";
import MetaComponent from "../../components/Elements/MetaData";
import { Footer } from "../../components/Layouts/Footer/Footer";
import { Nav } from "../../components/Layouts/Nav";
import { showAlert } from "../../helper";

export function FormCareer() {
  const { karir_url } = useParams();
  const MySwal = withReactContent(Swal);
  let onFirstLoad = true;
  const [onSubmit, setSubmit] = useState(false);

  const [provinsiList, setProvinsi] = useState([]);
  const [kabupatenList, setKabupaten] = useState([]);
  const [kecamatanList, setKecamatan] = useState([]);
  const [kelurahanList, setKelurahan] = useState([]);
  const [onLoadProvinsi, setLoadProvinsi] = useState(false);
  const [onLoadKabupaten, setLoadKabupaten] = useState(false);
  const [onLoadKecamatan, setLoadKecamatan] = useState(false);
  const [onLoadKelurahan, setLoadKelurahan] = useState(false);


  const [onloadPage, setLoading] = useState(true);
  const [careerContent, setContent] = useState({
    id: '',
    banner: '',
    short_url: '',
    title: '',
    deadline: '',
    deskripsi: '',
    terms_condition: '',
  })

  const [formNama, setNama] = useState('')
  const [formNoHp, setNoHp] = useState('')
  const [formEmail, setEmail] = useState('')
  const [formNik, setNik] = useState('')
  const [formAddress, setAddress] = useState('')

  const [provinsiId, setProvinsiId] = useState({});
  const [kabupatenId, setKabupatenId] = useState({});
  const [kecamatanId, setKecamatanId] = useState({});
  const [kelurahanId, setKelurahanId] = useState({});

  //file
  const inputFoto = useRef(null);
  const inputKtp = useRef(null);
  const inputIjazah = useRef(null);
  const inputCv = useRef(null);

  const [fileFoto, setFileFoto] = useState(["Tidak ada file yang dipilih"]);
  const [fileKtp, setFileKtp] = useState(["Tidak ada file yang dipilih"]);
  const [fileIjazah, setFileIjazah] = useState(["Tidak ada file yang dipilih"]);
  const [fileCv, setFileCv] = useState(["Tidak ada file yang dipilih"]);
  const [isActive, setIsActive] = useState(false);

  const [metaData, setMeta] = useState(({
    title: '',
    deskripsi: '',
    url: window.location.href,
    image: dataUrl.konten('cb8d1aa6-53c7-4600-ba43-55975d3268f2.png'),
    keywords: 'Cipta rasa jawara, jawara, sembilan, jogja, Yogyakarta, DI Yogyakarta',
  }))

  const clickSubmit = (event) => {
    event.preventDefault();

    if (isActive && formNama && formNoHp && formEmail && formNik && formAddress && provinsiId.value && kabupatenId.value && kecamatanId.value && kelurahanId.value && inputFoto.current.files.length > 0 && inputKtp.current.files.length > 0 && inputIjazah.current.files.length > 0 && inputCv.current.files.length > 0) {
      setSubmit(true);
      var formData = new FormData();
      formData.append('karir_id', careerContent.id)
      formData.append('nama', formNama);
      formData.append('no_hp', formNoHp);
      formData.append('email', formEmail);
      formData.append('nik', formNik);
      formData.append('alamat', formAddress);
      formData.append('provinsi_id', provinsiId.value)
      formData.append('kabupaten_id', kabupatenId.value)
      formData.append('kecamatan_id', kecamatanId.value)
      formData.append('kelurahan_id', kelurahanId.value)

      formData.append('file_foto', inputFoto.current.files[0])
      formData.append('file_ktp', inputKtp.current.files[0])
      formData.append('file_ijazah', inputIjazah.current.files[0])
      formData.append('file_cv', inputCv.current.files[0])

      post_StoreCareer(formData, () => {
        setSubmit(false)
        setNama('')
        setNik('')
        setNoHp('')
        setEmail('')
        setAddress('')
        setProvinsiId({})
        setKabupatenId({})
        setKecamatanId({})
        setKecamatanId({})

        inputFoto.value = '';
        inputKtp.value = '';
        inputIjazah.value = '';
        inputCv.value = '';
        setIsActive(false);

        setFileFoto("Tidak ada file yang dipilih")
        setFileKtp("Tidak ada file yang dipilih")
        setFileIjazah("Tidak ada file yang dipilih")
        setFileCv("Tidak ada file yang dipilih")

        MySwal.fire({
          html: `
          <div><img src=${imgSuccess} style="width: 80px;"></img></div>
          <div class="mt-3 text">
            <b style="font-size: 16px; margin-bottom:10px;">Lamaran Anda telah dikirimkan</b>
            <p style="font-size: 14px;margin-top: 10px;">Harap tunggu informasi lebih lanjut dalam beberapa hari mendatang, yang akan dikirimkan kepada Anda melalui email.</p>
          </div>`,
          confirmButtonText: "Tutup",
          background: "#fff",
          color: "#2B2F33",
          padding: "2em",
          confirmButtonColor: "#00753E",
          customClass: {
            confirmButton: "button-alert",
          },
        });
      }, () => {
        setSubmit(false);
        showAlert(MySwal, {
          type: 'warning',
          msg: 'Terjadi kesalahan dalam menyimpan data, silakan ulangi kembali nanti',
          buttonText: 'Tutup'
        })
      })
    } else {
      showAlert(MySwal, {
        type: 'warning',
        msg: 'Harap isi semua form terlebih dahulu',
        buttonText: 'Tutup'
      })
    }
  };

  const handleFileChangeFoto = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      setFileFoto('Tidak ada file yang dipilih');
      return;
    }
    setFileFoto(fileObj.name);
  };

  const handleFileChangeKtp = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      setFileKtp('Tidak ada file yang dipilih');
      return;
    }
    setFileKtp(fileObj.name);
  };
  const handleFileChangeIjazah = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      setFileIjazah('Tidak ada file yang dipilih');
      return;
    }
    setFileIjazah(fileObj.name);
  };
  const handleFileChangeCv = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      setFileCv('Tidak ada file yang dipilih');
      return;
    }
    setFileCv(fileObj.name);
  };

  const handelAgreement = (e) => {
    setIsActive(!isActive);
  }

  const getData = () => {
    setLoading(true)
    get_DetailCareer(karir_url, (res) => {
      const { data } = res
      console.log(data)
      setContent({
        id: data.id,
        banner: data.banner,
        short_url: data.short_url,
        title: data.title,
        deadline: moment(data.deadline).format('DD MMMM YYYY'),
        deskripsi: data.deskripsi,
        terms_condition: data.terms_condition,
      })

      setMeta(value => ({
        ...value,
        title: data.title + ' | Cipta Rasa Jawara',
        deskripsi: data.deskripsi,
        image: dataUrl.karir(data.banner),
      }));
      setLoading(false)
    })
  }

  const get_Provinsi = () => {
    setLoadProvinsi(true)

    setKabupatenId({})
    setKecamatanId({})
    setKelurahanId({})
    setKabupaten([])
    setKecamatan([])
    setKelurahan([])

    get_ProvinsiList((res) => {
      const { list } = res
      var item = []
      for (const key in list) {
        if (Object.hasOwnProperty.call(list, key)) {
          const element = list[key];
          item.push({
            value: element.id,
            label: element.nama
          })
        }
      }

      setProvinsi(item);

      setLoadProvinsi(false)
    })
  }

  const get_Kabupaten = (provinsi_id) => {
    setLoadKabupaten(true)

    setKecamatan([])
    setKelurahan([])
    setKabupatenId({})

    setKecamatanId({})
    setKelurahanId({})
    if (provinsi_id) {
      get_KabupatenList(provinsi_id, (res) => {
        const { list } = res
        var item = []
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            item.push({
              value: element.id,
              label: element.nama
            })
          }
        }

        setKabupaten(item);

        setLoadKabupaten(false)
      })
    } else {
      setLoadKabupaten(false)
    }
  }

  const get_Kecamatan = (kabupaten_id) => {
    setLoadKecamatan(true)

    setKelurahan([])
    setKecamatanId({})
    setKelurahanId({})

    if (kabupaten_id) {
      get_KecamatanList(kabupaten_id, (res) => {
        const { list } = res
        var item = []
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            item.push({
              value: element.id,
              label: element.nama
            })
          }
        }

        setKecamatan(item);

        setLoadKecamatan(false)
      })
    } else {
      setLoadKecamatan(false)
    }
  }

  const currentValue = (obj) => {
    if (obj && obj.value) {
      return obj.text
    }
    return '';
  }

  const get_Kelurahan = (kecamatan_id) => {
    setLoadKelurahan(true)

    setKelurahanId({})
    if (kecamatan_id) {
      get_KelurahanList(kecamatan_id, (res) => {
        const { list } = res
        var item = []
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            item.push({
              value: element.id,
              label: element.nama
            })
          }
        }

        setKelurahan(item);

        setLoadKelurahan(false)
      })
    } else {
      setLoadKelurahan(false)
    }
  }

  useEffect(() => {
    if (onFirstLoad) {
      onFirstLoad = false;
      get_Provinsi()
      getData();
    }
  }, [])

  useEffect(() => {
    get_Kabupaten(provinsiId.value)
  }, [provinsiId])

  useEffect(() => {
    get_Kecamatan(kabupatenId.value)
  }, [kabupatenId])

  useEffect(() => {
    get_Kelurahan(kecamatanId.value)
  }, [kecamatanId])

  return (
    <>
      <MetaComponent keywords={metaData.keywords} pageImage={metaData.image} pageTitle={metaData.title} pageDescription={metaData.deskripsi} url={metaData.url}></MetaComponent>
      <Nav active="career"></Nav>
      <div className="career">
        <div className="container">
          <div className="title-section">Formulir Pendaftaran</div>
          <div className="row mt-4">
            <div className="col-md-4 col-12">
              <div className="list-career">
                {onloadPage ?
                  <SkeletonCareer></SkeletonCareer>
                  :
                  <CardCarer
                    banner={careerContent.banner}
                    title={careerContent.title}
                    deadline={careerContent.deadline}
                    desc={careerContent.deskripsi}
                  ></CardCarer>
                }
              </div>
            </div>
            <div className="col-md-8 col-12">
              <form onSubmit={clickSubmit}>
                <div className="form-career">
                  <div className="mb-4">
                    <label className="form-label">Nama <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Masukkan Nama Anda"
                      required={true}
                      value={formNama}
                      onChange={(e) => setNama(e.target.value)}
                    ></input>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="mb-4">
                        <label className="form-label">Nomor Telepon <span className="text-danger">*</span></label>
                        <ReactInputMask mask="999999999999" maskChar="" className="form-control" required={true} placeholder="Masukkan No Telepon Anda" name="no_hp" value={formNoHp}
                          onChange={(e) => setNoHp(e.target.value)}>
                        </ReactInputMask>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-4">
                        <label className="form-label">Email <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Masukkan Email Anda"
                          required={true}
                          value={formEmail}
                          onChange={(e) => setEmail(e.target.value)}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">NIK <span className="text-danger">*</span></label>
                    <ReactInputMask mask="999999999999999999999999999" maskChar="" className="form-control" required={true} placeholder="Masukkan NIK Anda" name="nik" value={formNik}
                      onChange={(e) => setNik(e.target.value)}>
                    </ReactInputMask>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="mb-4">
                        <label className="form-label">Provinsi <span className="text-danger">*</span></label>
                        <Select options={provinsiList} required={true} value={currentValue(provinsiId)} placeholder={onLoadProvinsi ? 'Loading...' : "Pilih"} onChange={e => setProvinsiId(e)} />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-4">
                        <label className="form-label">Kota/Kabupaten <span className="text-danger">*</span></label>
                        <Select options={kabupatenList} required={true} value={currentValue(kabupatenId)} placeholder={onLoadKabupaten ? 'Loading...' : "Pilih"} onChange={e => setKabupatenId(e)} />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-4">
                        <label className="form-label">Kecamatan <span className="text-danger">*</span></label>
                        <Select options={kecamatanList} required={true} value={currentValue(kecamatanId)} placeholder={onLoadKecamatan ? 'Loading...' : "Pilih"} onChange={e => setKecamatanId(e)} />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-4">
                        <label className="form-label">Kelurahan <span className="text-danger">*</span></label>
                        <Select value={currentValue(kelurahanId)} required={true} options={kelurahanList} placeholder={onLoadKelurahan ? 'Loading...' : "Pilih"} onChange={e => setKelurahanId(e)} />
                      </div>
                    </div>
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Alamat <span className="text-danger">*</span></label>
                    <textarea
                      className="form-control"
                      placeholder="Alamat"
                      value={formAddress}
                      onChange={(e) => setAddress(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Pas Foto Terbaru <span className="text-danger">*</span></label>
                    <div className="row">
                      <div className="col-md-9 col-12">
                        <input
                          ref={inputFoto}
                          hidden
                          onChange={handleFileChangeFoto}
                          type="file"
                          accept=".jpg,.png"
                          className="form-control"
                          placeholder="Tidak ada file yang dipilih"
                          required
                        ></input>
                        <div className="wrapper-input">
                          <div className="text">{fileFoto}</div>
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <button
                          className="btn btn-file"
                          type="button"
                          onClick={() => inputFoto.current.click()}
                        >
                          Pilih File
                        </button>
                      </div>
                    </div>
                    <div className="type-file">Tipe file : .jpg, .png</div>
                  </div>

                  <div className="mb-4">
                    <label className="form-label">KTP <span className="text-danger">*</span></label>
                    <div className="row">
                      <div className="col-md-9 col-12">
                        <input
                          ref={inputKtp}
                          hidden
                          onChange={handleFileChangeKtp}
                          type="file"
                          accept=".jpg,.png"
                          className="form-control"
                          placeholder="Tidak ada file yang dipilih"
                          required
                        ></input>
                        <div className="wrapper-input">
                          <div className="text">{fileKtp}</div>
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <button
                          className="btn btn-file"
                          type="button"
                          onClick={() => inputKtp.current.click()}
                        >
                          Pilih File
                        </button>
                      </div>
                    </div>
                    <div className="type-file">Tipe file : .jpg, .png</div>
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Ijazah <span className="text-danger">*</span></label>
                    <div className="row">
                      <div className="col-md-9 col-12">
                        <input
                          ref={inputIjazah}
                          hidden
                          onChange={handleFileChangeIjazah}
                          type="file"
                          accept=".pdf"
                          className="form-control"
                          placeholder="Tidak ada file yang dipilih"
                          required
                        ></input>
                        <div className="wrapper-input">
                          <div className="text">{fileIjazah}</div>
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <button
                          className="btn btn-file"
                          type="button"
                          onClick={() => inputIjazah.current.click()}
                        >
                          Pilih File
                        </button>
                      </div>
                    </div>
                    <div className="type-file">Tipe file : .pdf</div>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">CV/Resume <span className="text-danger">*</span></label>
                    <div className="row">
                      <div className="col-md-9 col-12">
                        <input
                          ref={inputCv}
                          hidden
                          onChange={handleFileChangeCv}
                          type="file"
                          accept=".pdf"
                          className="form-control"
                          placeholder="Tidak ada file yang dipilih"
                          required
                        ></input>
                        <div className="wrapper-input">
                          <div className="text">{fileCv}</div>
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <button
                          type="button"
                          className="btn btn-file"
                          onClick={() => inputCv.current.click()}
                        >
                          Pilih File
                        </button>
                      </div>
                    </div>
                    <div className="type-file">Tipe file : .pdf</div>
                  </div>
                </div>
                <div className="agreement mt-5 mb-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="true"
                      id="flexCheckDefault"
                      checked={isActive}
                      onChange={handelAgreement}
                    ></input>
                    <label className="form-check-label">
                      Saya menyetujui Ketentuan Layanan dan Pernyataan Privasi
                      PT Cipta Rasa Jawara, dan lamaran saya dikirim ke
                      perusahaan terkait dan diproses sesuai dengan kebijakan
                      privasi perusahaan.
                    </label>

                  </div>
                </div>
                <button
                  className="btn btn-green mt-4"
                  disabled={!isActive || onSubmit}
                  id="btn-submit"
                >
                  {onSubmit ? <FontAwesomeIcon icon={faSpinner} spin={true}></FontAwesomeIcon> : 'Lamar'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
