import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// import { Nav } from "./parts/Nav";
import 'react-loading-skeleton/dist/skeleton.css';
import ScrollToTop from "./helpers/ScrollToTop";
import { AboutUs } from "./pages/AboutUs";
import { Activity } from "./pages/Activity/Activity";
import { DetailActivity } from "./pages/Activity/DetailActivity";
import { Brand } from "./pages/Brand/Brand";
import { DetailBrand } from "./pages/Brand/DetailBrand";
import { Career } from "./pages/Career/Career";
import { DetailCareer } from "./pages/Career/DetailCareer";
import { FormCareer } from "./pages/Career/FormCareer";
import { ContactUs } from "./pages/ContactUs";
import { Distributor } from "./pages/Distributor/Distributor";
import { ListDistributor } from './pages/Distributor/ListDistributor';
import { Home } from "./pages/Home";
import { Ikt } from "./pages/Ikt";
import { DetailMitra } from "./pages/Mitra/DetailMitra";
import { Mitra } from "./pages/Mitra/Mitra";

export default function App() {
  return (
    <div>
      <Router>
        <div className="App">
          <ScrollToTop></ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/about-us" element={<AboutUs />}></Route>
            <Route path="/brand" element={<Brand />}></Route>
            <Route path="/brand/:brand_url" element={<DetailBrand />}></Route>
            <Route path="/mitra" element={<Mitra />}></Route>
            <Route path="/mitra/istanbul-kebab-turki" element={<Ikt />}></Route>
            <Route path="/mitra/:mitra_url" element={<DetailMitra />}></Route>
            <Route path="/distributor" element={<ListDistributor />}></Route>
            <Route path="/distributor/:distributor_url" element={<Distributor />}></Route>
            <Route path="/contact-us" element={<ContactUs />}></Route>
            <Route path="/career" element={<Career />}></Route>
            <Route path="/career/:karir_url" element={<DetailCareer />}></Route>
            <Route path="/career/:karir_url/form" element={<FormCareer />}></Route>
            <Route path="/aktivitas" element={<Activity />}></Route>
            <Route path="/aktivitas/:artikel_url" element={<DetailActivity />}></Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
}
