import React, { useEffect, useState } from "react";
import { dataUrl, get_HomeData, get_Metadata } from "../actions";
import "../assets/scss/main/main.css";
import MetaComponent from "../components/Elements/MetaData";
import { Footer } from "../components/Layouts/Footer/Footer";
import { Activity } from "../components/Layouts/Home/Activity";
import { Brand } from "../components/Layouts/Home/Brand";
import { Distributor } from "../components/Layouts/Home/Distributor";
import { GetInTouch } from "../components/Layouts/Home/GetInTouch";
import { Hero } from "../components/Layouts/Home/Hero";
import { Mission } from "../components/Layouts/Home/Mission";
import { Mitra } from "../components/Layouts/Home/Mitra";
import { VideoEmbed } from "../components/Layouts/Home/VideoEmbed";
import { Nav } from "../components/Layouts/Nav";

export function Home() {
  let firstLoad = true;
  const [onloadPage, setLoading] = useState(true);
  const [aboutUsConten, setAboutUs] = useState({
    desc: '',
  })

  const [brandContent, setBrand] = useState({
    desc: '',
    list: []
  })

  const [mitraContent, setMitra] = useState({
    desc: '',
    list: []
  })

  const [distributorContent, setDistributor] = useState([])

  const [videoUrl, setVideoUrl] = useState('')


  const [activityContent, setActivity] = useState({
    desc: '',
    list: []
  })


  const [metaData, setMeta] = useState(({
    title: '',
    deskripsi: '',
    url: window.location.href,
    image: dataUrl.konten('cb8d1aa6-53c7-4600-ba43-55975d3268f2.png'),
    keywords: 'Cipta rasa jawara, jawara, sembilan, jogja, Yogyakarta, DI Yogyakarta',
  }))


  const getData = () => {
    setLoading(true);
    get_HomeData((res) => {
      const { content, brand, mitra, distributor, aktivitas, video_profile, meta } = res;
      setAboutUs({
        desc: content.tentang_kami
      })

      setBrand({
        desc: content.brand_kami,
        list: brand
      })

      setMitra({
        desc: content.bermitra_dengan,
        list: mitra
      })

      setDistributor(distributor)

      setActivity({
        desc: content.aktifitas_kami,
        list: aktivitas
      })

      setVideoUrl(video_profile)

      setLoading(false);
    })
  }

  const getMeta = () => {
    get_Metadata((res) => {
      const { meta } = res
      setMeta(value => ({
        ...value,
        title: meta.judul_website,
        deskripsi: meta.deskripsi_page,
        image: meta.logo_page,
      }))
    })
  }

  useEffect(() => {
    if (firstLoad) {
      firstLoad = false;
      getMeta();
      getData();
    }
  }, [])
  // setTimeout(() => {
  //   setLoading(false)
  // }, 4000);
  return (
    <>
      <MetaComponent keywords={metaData.keywords} pageImage={metaData.image} pageTitle={metaData.title} pageDescription={metaData.deskripsi} url={metaData.url}></MetaComponent>
      <Nav active="home"></Nav>
      <Hero></Hero>
      <Mission content={aboutUsConten} load={onloadPage}></Mission>
      <Brand content={brandContent} load={onloadPage}></Brand>
      <Mitra content={mitraContent} load={onloadPage}></Mitra>
      <GetInTouch></GetInTouch>
      <Distributor content={distributorContent} load={onloadPage}></Distributor>
      <Activity content={activityContent} load={onloadPage}></Activity>
      {videoUrl ?
        <VideoEmbed url={videoUrl} load={onloadPage}></VideoEmbed>
        : <div style={{ marginBottom: '4.5rem' }}></div>
      }
      <Footer></Footer>
    </>
  );
}
