import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "../../../src/assets/scss/home/activity/activity.css";
import "../../../src/assets/scss/main/main.css";
import { dataUrl, get_ListArtikel, get_Metadata } from "../../actions";
import LinkRoute from "../../components/Elements/Link/index";
import MetaComponent from "../../components/Elements/MetaData";
import Title from "../../components/Elements/Title";
import { Footer } from "../../components/Layouts/Footer/Footer";
import { Nav } from "../../components/Layouts/Nav";

export function Activity() {
  let firstLoad = true;
  const [onloadPage, setLoading] = useState(true);

  const [activityContent, setContent] = useState({
    desc: '',
    first: [],
    second: [],
  })

  const [metaData, setMeta] = useState(({
    title: '',
    deskripsi: '',
    url: window.location.href,
    image: dataUrl.konten('cb8d1aa6-53c7-4600-ba43-55975d3268f2.png'),
    keywords: 'Cipta rasa jawara, jawara, sembilan, jogja, Yogyakarta, DI Yogyakarta',
  }))

  const getMeta = () => {
    get_Metadata((res) => {
      const {meta} = res
      setMeta(value => ({...value,
        title: 'Aktifitas | '+ meta.judul_website,
        deskripsi: meta.deskripsi_page,
        image: meta.logo_page,
      }))
    })
  }


  const getData = () => {
    setLoading(true)

    get_ListArtikel((res) => {
      const { content, list } = res
      setContent({
        desc: content,
        first: list.slice(0, 4),
        second: list.slice(4)
      })

      setLoading(false);
    })
  }

  useEffect(() => {
    if (firstLoad) {
      firstLoad = true;
      getData();
      getMeta();
    }
  }, [])

  return (
    <>
      <MetaComponent keywords={metaData.keywords} pageImage={metaData.image} pageTitle={metaData.title} pageDescription={metaData.deskripsi} url={metaData.url}></MetaComponent>
      <Nav active="home"></Nav>
      <div className="activity-page ">
        <div className="container">
          <Title>Aktivitas Kami</Title>
          {onloadPage ?
            <div className="desc-section mt-4 text-center">
              <Skeleton height={15} count={3} borderRadius={5}></Skeleton>
            </div>
            :
            <div className="desc-section mt-4 text-center" dangerouslySetInnerHTML={{ __html: activityContent.desc }}>
            </div>
          }
          <div className="list-activity">
            {onloadPage ? <>
              <div className="item-activity item-activity-1 ">
                <Skeleton borderRadius={5} style={{ width: '100%' }} height={500}></Skeleton>
                <div className="title-item mt-4"><Skeleton borderRadius={5} height={40}></Skeleton></div>
                <div className="desc-item">
                  <Skeleton borderRadius={5} height={20} count={3}></Skeleton>
                </div>
              </div>
              <div className="item-activity item-activity-2 item-right">
                <div className="d-flex">
                  <Skeleton borderRadius={10} style={{ width: '255px' }} height={225}></Skeleton>
                  <div>
                    <div className="title-item ms-3">
                      <Skeleton borderRadius={5} width={300} height={40}></Skeleton>
                    </div>
                    <div className="desc-item ms-3">
                      <Skeleton borderRadius={5} width={300} height={20} count={3}></Skeleton>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-activity item-activity-3 item-right">
                <div className="d-flex">
                  <Skeleton borderRadius={10} style={{ width: '255px' }} height={225}></Skeleton>
                  <div>
                    <div className="title-item ms-3">
                      <Skeleton borderRadius={5} width={300} height={40}></Skeleton>
                    </div>
                    <div className="desc-item ms-3">
                      <Skeleton borderRadius={5} width={300} height={20} count={3}></Skeleton>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-activity item-activity-4 item-right">
                <div className="d-flex">
                  <Skeleton borderRadius={10} style={{ width: '255px' }} height={225}></Skeleton>
                  <div>
                    <div className="title-item ms-3">
                      <Skeleton borderRadius={5} width={300} height={40}></Skeleton>
                    </div>
                    <div className="desc-item ms-3">
                      <Skeleton borderRadius={5} width={300} height={20} count={3}></Skeleton>
                    </div>
                  </div>
                </div>
              </div>
            </>
              :
              <>
                {activityContent.first.map((item, index) => (
                  <>
                    {(index > 0) ?
                      <>
                        <div className={`item-activity item-activity-${index + 1} item-right`}>
                          <LinkRoute route={`/aktivitas/${item.short_url}`}>
                            <div className="d-flex flex-wrap">
                              <img src={dataUrl.konten(item.thumbnail)} alt={item.thumbnail}></img>
                              <div className="content-text">
                                <div className="title-item ms-3">
                                  {item.judul}
                                </div>
                                <div className="desc-item ms-3" dangerouslySetInnerHTML={{ __html: item.isi }}>
                                </div>
                              </div>
                            </div>
                          </LinkRoute>
                        </div>
                      </>
                      : <>
                        <div className={`item-activity item-activity-1`}>
                          <LinkRoute route={`/aktivitas/${item.short_url}`}>

                            <img src={dataUrl.konten(item.thumbnail)} alt={item.thumbnail}></img>
                            <div className="title-item mt-4">{item.judul}</div>
                            <div className="desc-item" dangerouslySetInnerHTML={{ __html: item.isi }}>
                            </div>
                          </LinkRoute>
                        </div>
                      </>
                    }
                  </>
                ))}
              </>}
          </div>

          <div className="row list-activity-bottom">
            {onloadPage ? <>
              {['', '', ''].map((item, index) => (
                <div className="col-md-4 col-12" key={'skeleton' + index}>
                  <LinkRoute route="/aktivitas/detail">
                    <div className="item-activity">
                      <Skeleton borderRadius={5} height={200} width={'100%'}></Skeleton>
                      <div className="title-item mt-4"><Skeleton borderRadius={5} height={40}></Skeleton></div>
                      <div className="desc-item">
                        <Skeleton borderRadius={5} count={2} height={20}></Skeleton>
                      </div>
                    </div>
                  </LinkRoute>
                </div>
              ))}
            </>
              : <>
              {activityContent.second.map((item, index) => (
                <div className="col-md-4 col-12">
                <LinkRoute route={`/aktivitas/${item.short_url}`}>
                  <div className="item-activity">
                    <img src={dataUrl.konten(item.thumbnail)} alt={dataUrl.konten(item.thumbnail)}></img>
                    <div className="title-item mt-4">{item.judul}</div>
                    <div className="desc-item" dangerouslySetInnerHTML={{ __html: item.isi }}>
                    </div>
                  </div>
                </LinkRoute>
              </div>
              ))}
              </>
            }
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
