import { dataUrl } from "../../../actions";
import iconClock from "../../../assets/icon/icon-clock.svg";
import bgDefault from "../../../assets/img/career/default.png";
import "../../../assets/scss/career/career.css";
import LinkRoute from "../../../components/Elements/Link/index";
const CardCareer = (props) => {
  const { title, deadline, desc, short_url, banner } = props;
  return (
    <div className="item-career">
      <div className="hader-career">
        <div className="banner-career mb-3">
          <img src={banner ? dataUrl.karir(banner) : bgDefault} alt="banner"></img>
        </div>
        <div className="title-career">{title}</div>
        <div className="deadline-career d-flex align-items-center">
          <div className="me-2">
            <img src={iconClock} alt={iconClock}></img>
          </div>
          <div>Deadline : {deadline}</div>
        </div>
      </div>
      <div className="line"></div>
      <div className="desc-career">{desc}</div>
      <div className="line"></div>
      <LinkRoute route={`/career/${short_url}`}>
        <div className="detail-career">Detail</div>
      </LinkRoute>
    </div>
  );
};

export default CardCareer;
