// import { create } from 'apisauce';
import axios from "axios"
import Cookies from "universal-cookie"

const cksClient = () => {
    var cookies = new Cookies()

    return cookies
}

const apiClient = (withBearer = true, type = 'apisauce') => {
    var _url = process.env.REACT_APP_API_URL
    if (window.location.origin == "https://ciptarasajawara.com" || window.location.origin == "https://www.ciptarasajawara.com") {
        _url = "https://api.ciptarasajawara.com/api";
    }
    const _tkn = cksClient().get('_authToken')
    const options = {
        baseURL: _url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 600000,
    }

    if (withBearer) {
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${_tkn}`
        }
    }
    
    const client = axios.create(options)
    client.interceptors.request.use(
        requestConfig => requestConfig,
        requestError => {
            return Promise.reject(requestError)
        }
    )

    client.interceptors.response.use(
        response => Promise.resolve(response),
        error => {
            if (error.response) {
                const {status} = error.response
                if (status === 403) {
                    console.log('Global api check - Forbidden Access')
                }
            }

            return Promise.reject(error)
        }
    )

    // if (type == "apisauce") {
    //     return create({axiosInstance: client})
    // }

    return client
}

const showAlert = (swal, data = {
    type: 'warning',
    msg: 'Browser anda tidak support menggunakan kamera, silakan gunakan browser seperti Chrome atau Firefox',
    buttonText: 'Oke',
}) => {
    var icon = `<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.9999 71.3667H19.7999C8.23327 71.3667 3.39994 63.1 8.99994 53L19.3999 34.2667L29.1999 16.6667C35.1333 5.96666 44.8666 5.96666 50.7999 16.6667L60.5999 34.3L70.9999 53.0333C76.5999 63.1333 71.7333 71.4 60.1999 71.4H39.9999V71.3667Z" fill="#FFF2D0" stroke="#DDAD48" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M40 30V46.6667" stroke="#DDAD48" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M39.9817 56.6667H40.0116" stroke="#DDAD48" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

    if (data.type == 'danger') {
        icon = `<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.0001 73.3333C58.3334 73.3333 73.3334 58.3333 73.3334 40C73.3334 21.6667 58.3334 6.66667 40.0001 6.66667C21.6667 6.66667 6.66675 21.6667 6.66675 40C6.66675 58.3333 21.6667 73.3333 40.0001 73.3333Z" fill="#FFE3E3" stroke="#E93232" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M30.5667 49.4333L49.4333 30.5667" stroke="#E93232" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M49.4333 49.4333L30.5667 30.5667" stroke="#E93232" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`
    } else if (data.type == 'success') {
        icon = `<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.0001 73.3333C58.3334 73.3333 73.3334 58.3333 73.3334 40C73.3334 21.6667 58.3334 6.66666 40.0001 6.66666C21.6667 6.66666 6.66675 21.6667 6.66675 40C6.66675 58.3333 21.6667 73.3333 40.0001 73.3333Z" fill="#C4FFE3" stroke="#00753E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M25.8333 40L35.2666 49.4334L54.1666 30.5667" stroke="#00753E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`;
    }
    swal.fire({
        html: `
        <div>
        ${icon}
        </div>
        <div class="mt-3 text">
          <div style="font-size: 16px; margin-bottom:10px; color:#263238;font-weight: 500;">${data.msg}</div>
        </div>`,
        confirmButtonText: data.buttonText,
        background: "#fff",
        color: "#2B2F33",
        padding: "2em",
        confirmButtonColor: "#3C6125",
        customClass: {
          confirmButton: "button-alert",
        },
      })
}

export {
    apiClient, cksClient, showAlert
}
