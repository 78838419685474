import { apiClient } from "../helper";
var _url = process.env.REACT_APP_DATA_URL;
if (window.location.origin == "https://ciptarasajawara.com" || window.location.origin == "https://www.ciptarasajawara.com") {
    _url = "https://storage.ciptarasajawara.com";
}
const dataUrl = {
    brand: (string) => {
        return `${_url}/brand/${string}`
    },
    mitra: (string) => {
        return `${_url}/mitra/${string}`
    },
    distributor: (string) => {
        return `${_url}/distributor/${string}`
    },
    konten: (string) => {
        return `${_url}/konten/${string}`
    },
    karir: (string) => {
        return `${_url}/karir/${string}`
    }
}

const URL = {
    home: '/home',
    social: '/home/social',
    meta: '/home/meta',
    aboutUs: '/home/tentang-kami',
    brand: '/home/brand',
    brand_detail: '/home/brand/:url',
    mitra: '/home/mitra',
    mitra_detail: '/home/mitra/:url',
    distributor: '/home/distributor',
    distributor_detail: '/home/distributor/:url',
    karir: '/home/karir',
    karir_detail: '/home/karir/:url',
    karir_store: '/home/karir/application',
    kontak: '/home/kontak',
    kontak_store: '/home/kontak/store',
    artikel: '/home/artikel',
    artikel_detail: '/home/artikel/:url',
    ikt: '/home/istanbul-kebab-turki',
    provinsi: '/master/provinsi',
    kabupaten: '/master/kabupaten',
    kecamatan: '/master/kecamatan',
    kelurahan: '/master/kelurahan',
}

const get_HomeData = (successCallback, errorCallback) => {
    apiClient(false).get(URL.home).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_SocialData = (successCallback, errorCallback) => {
    apiClient(false).get(URL.social).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_Metadata = (successCallback, errorCallback) => {
    apiClient(false).get(URL.meta).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_AboutUsData = (successCallback, errorCallback) => {
    apiClient(false).get(URL.aboutUs).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_ListBrand = (successCallback, errorCallback) => {
    apiClient(false).get(URL.brand).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_DetailBrand = (url, successCallback, errorCallback) => {
    apiClient(false).get(URL.brand_detail.replace(':url', url)).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_ListMitra = (successCallback, errorCallback) => {
    apiClient(false).get(URL.mitra).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_DetailMitra = (url, successCallback, errorCallback) => {
    apiClient(false).get(URL.mitra_detail.replace(':url', url)).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_ListDistributor = (successCallback, errorCallback) => {
    apiClient(false).get(URL.distributor).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_DetailDistributor = (url, successCallback, errorCallback) => {
    apiClient(false).get(URL.distributor_detail.replace(':url', url)).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_ListCareer = (successCallback, errorCallback) => {
    apiClient(false).get(URL.karir).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_DetailCareer = (url, successCallback, errorCallback) => {
    apiClient(false).get(URL.karir_detail.replace(':url', url)).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        console.log(err)
        if (errorCallback) {
            errorCallback(err && err.response ? err.response.data : err)
        }
    })
}

const post_StoreCareer = (params, successCallback, errorCallback) => {
    apiClient(false).post(URL.karir_store, params, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        console.log(err)
        if (errorCallback) {
            errorCallback(err && err.response ? err.response.data : err)
        }
    })
}

const get_DataKontak = (successCallback, errorCallback) => {
    apiClient(false).get(URL.kontak).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err && err.response ? err.response.data : err)
        }
    })
}

const post_StoreKontak = (params, successCallback, errorCallback) => {
    apiClient(false).post(URL.kontak_store, params, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_ListArtikel = (successCallback, errorCallback) => {
    apiClient(false).get(URL.artikel).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_DetailArtikel = (url, successCallback, errorCallback) => {
    apiClient(false).get(URL.artikel_detail.replace(':url', url)).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_DataIKT = (successCallback, errorCallback) => {
    apiClient(false).get(URL.ikt).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_ProvinsiList = (successCallback, errorCallback) => {
    apiClient(false).get(URL.provinsi).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_KabupatenList = (id, successCallback, errorCallback) => {
    apiClient(false).get(URL.kabupaten + `?provinsi_id=${id}`).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_KecamatanList = (id, successCallback, errorCallback) => {
    apiClient(false).get(URL.kecamatan + `?kabupaten_id=${id}`, {
        kabupaten_id: id
    }).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

const get_KelurahanList = (id, successCallback, errorCallback) => {
    apiClient(false).get(URL.kelurahan + `?kecamatan_id=${id}`, {
        kecamatan_id: id
    }).then((result) => {
        if (result.status === 200) {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback) {
            errorCallback(err.response.data)
        }
    })
}

export {
    dataUrl,
    get_AboutUsData,
    get_DataIKT,
    get_DataKontak,
    get_DetailArtikel,
    get_DetailBrand,
    get_DetailCareer,
    get_DetailDistributor,
    get_DetailMitra,
    get_HomeData,
    get_KabupatenList,
    get_KecamatanList,
    get_KelurahanList,
    get_ListArtikel,
    get_ListBrand,
    get_ListCareer,
    get_ListDistributor,
    get_ListMitra,
    get_Metadata,
    get_ProvinsiList,
    get_SocialData,
    post_StoreCareer,
    post_StoreKontak
};

