import imgSquare from "../../../assets/img/img-square-1.png";
import "../../../assets/scss/main/main.css";
const Title = (props) => {
  const { children, left = "40%" } = props;
  return (
    <div className="title-section text-center d-flex justify-content-center">
      <img
        src={imgSquare}
        alt={imgSquare}
        className="img-square"
        style={{
          position: "absolute",
          left: `${left}`,
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      ></img>
      <div>{children}</div>
    </div>
  );
};

export default Title;
