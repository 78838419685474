import React from "react";
// import "../../../assets/scss/home/mitra/mitra.css";
import Skeleton from "react-loading-skeleton";
import { dataUrl } from "../../../actions";
import iconShare from "../../../assets/icon/icon-arrow.svg";
import CardSkeleton from "../../Elements/CardSkeleton/index";
import CardBrand from "../../Elements/Card_Brand/index";
import LinkRoute from "../../Elements/Link";
export function Distributor(props) {
  const { load, content } = props;
  return (
    <div>
      <section className="distributor">
        <div className="container">
          <div className="row align-items-center">
            {load ? (
              <>
                <div className="col-12 col-lg-4 ">
                  <CardSkeleton></CardSkeleton>
                </div>
                <div className="col-lg-8 col-12">
                  <div className="title-section">
                    <Skeleton borderRadius={5} height={50} className="mb-3" />
                  </div>
                  <div>
                    <Skeleton borderRadius={5} height={20} count={4} />
                  </div>
                </div>
              </>
            ) : (
              <>
                {content.map((item, index) => (
                  <>
                    <div className="col-12 col-lg-4 ">
                      <div className="title-section d-bloc d-md-none">
                        Distributor <span>Terpercaya </span> {item.nama}
                      </div>
                      <CardBrand
                        src={dataUrl.distributor(item.logo)}
                        title={item.nama}
                        desc={item.short_deskripsi}
                      ></CardBrand>
                    </div>
                    <div className="col-lg-8 col-12">
                      <div className="title-section d-none d-md-block">
                        Distributor <span>Terpercaya </span> {item.nama}
                      </div>

                      <div className="mt-3 text-justify">{item.profile} </div>
                      <div className="mt-3 see-more">
                        <LinkRoute
                          variant="green-color"
                          route={`/distributor/${item.short_url}`}
                        >
                          Lihat Lebih{" "}
                          <img src={iconShare} alt={iconShare}></img>
                        </LinkRoute>
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
