import moment from "moment";
import 'moment/locale/id';
import React, { useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import "../../../src/assets/scss/career/career.css";
import "../../../src/assets/scss/main/main.css";
import { dataUrl, get_DetailCareer } from "../../actions";
import facebook from "../../assets/icon/facebook.svg";
import iconClock from "../../assets/icon/icon-clock.svg";
import copy from "../../assets/icon/icon-copy.svg";
import iconShare from "../../assets/icon/icon-share.svg";
import twitter from "../../assets/icon/twitter.svg";
import whatsapp from "../../assets/icon/whatsapp.svg";
import LinkRoute from "../../components/Elements/Link/index";
import MetaComponent from "../../components/Elements/MetaData";
import { Footer } from "../../components/Layouts/Footer/Footer";
import { Nav } from "../../components/Layouts/Nav";

export function DetailCareer() {
  const { karir_url } = useParams();
  const [show, setShow] = useState(false);
  let onFirstLoad = true;
  const [onloadPage, setLoading] = useState(true);
  const [careerContent, setContent] = useState({
    short_url: '',
    banner: '',
    title: '',
    deadline: '',
    deskripsi: '',
    terms_condition: '',
    link: '',
    isOpen: true,
  })

  const refShare = useRef(null);
  const [metaData, setMeta] = useState(({
    title: '',
    deskripsi: '',
    url: window.location.href,
    image: dataUrl.konten('cb8d1aa6-53c7-4600-ba43-55975d3268f2.png'),
    keywords: 'Cipta rasa jawara, jawara, sembilan, jogja, Yogyakarta, DI Yogyakarta',
  }))
  const getData = () => {
    setLoading(true)
    get_DetailCareer(karir_url, (res) => {
      const { data } = res
      var now = moment().unix();
      setContent({
        short_url: data.short_url,
        title: data.title,
        banner: data.banner ? dataUrl.karir(data.banner) : '',
        deadline: moment(data.deadline).format('DD MMMM YYYY'),
        isOpen: moment(data.deadline).unix() >= now,
        deskripsi: data.deskripsi,
        terms_condition: data.terms_condition,
        link: `${window.location.origin}/career/${data.short_url}`
      })

      setMeta(value => ({
        ...value,
        title: data.title + ' | Cipta Rasa Jawara',
        deskripsi: data.deskripsi,
      }));

      setLoading(false)
    })
  }

  useEffect(() => {
    if (onFirstLoad) {
      onFirstLoad = false;
      getData();
    }
    const handleClickOutside = (event) => {
      if (refShare.current && !refShare.current.contains(event.target)) {
        setShow(false);
      }
    };

    // Attach the event listener on mount
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [])

  return (
    <>
      <MetaComponent keywords={metaData.keywords} pageImage={metaData.image} pageTitle={metaData.title} pageDescription={metaData.deskripsi} url={metaData.url}></MetaComponent>
      <Nav active="career"></Nav>
      <div className="career career-detail">
        <div className="container">
          <div className="sub-title">{onloadPage ? <Skeleton height={35} borderRadius={10} width={250}></Skeleton> : 'Karir'}</div>
          <div className="title-section mt-2">{onloadPage ? <Skeleton height={50} borderRadius={10}></Skeleton> : careerContent.title}</div>

          <div className="deadline-career d-flex align-items-center">
            {onloadPage ? <Skeleton height={20} borderRadius={10} width={250}></Skeleton> : <>
              <div className="me-2">
                <img src={iconClock} alt={iconClock}></img>
              </div>
              <div>Deadline : {careerContent.deadline}</div></>}
          </div>
          {!onloadPage ?
            <div className="d-flex align-items-center mt-4">
              {careerContent.isOpen ?
                <LinkRoute route={`/career/${careerContent.short_url}/form`}>
                  <div className="btn-green btn">Lamar</div>
                </LinkRoute>
                :
                <div className="btn-green btn" style={{ backgroundColor: '#719e89', cursor: 'no-drop' }}>Lamar</div>
              }

              <div className="d-flex align-items-center share" onClick={() => setShow(!show)} ref={refShare}>
                <img src={iconShare} alt={iconShare} className="me-2"></img>
                <div className="" >Bagikan Karir Ini</div>
                <div className={`list-share  ${show ? "show" : ""}`}>
                  <ul className="d-flex flex-wrap justify-content-center justify-content-center justify-content-md-start">
                    <li>
                      <a target="_blank" data-tooltip-id="shareFb" data-tooltip-content="Bagikan di Facebook" href={`https://www.facebook.com/sharer/sharer.php?u=${careerContent.link}`}><img src={facebook} alt={facebook}></img>
                      </a>
                      <ReactTooltip id="shareFb" place="top" effect="solid">
                      </ReactTooltip>
                    </li>
                    <li>
                      <a target="_blank" data-tooltip-id="shareTwitter" data-tooltip-content="Bagikan di Twitter" href={`https://twitter.com/share?&url=${careerContent.link}`}><img src={twitter} alt={twitter}></img>
                      </a>
                      <ReactTooltip id="shareTwitter" place="top" effect="solid">
                      </ReactTooltip>
                    </li>
                    <li>
                      <a target="_blank" data-tooltip-id="shareWa" data-tooltip-content="Bagikan di Whatsapp" href={`https://wa.me?text=${careerContent.link}`}><img src={whatsapp} alt={whatsapp}></img>
                        <ReactTooltip id="shareWa" place="top" effect="solid">
                        </ReactTooltip>
                      </a>
                    </li>
                    <li data-toggle="tooltip" data-placement="top" title="Copy">
                      <CopyToClipboard text={`${window.location.origin}/career/${careerContent.short_url}`}>
                        <a data-tooltip-id="toolTipCopy" data-tooltip-content="Click to Copy">
                          <img src={copy} alt={copy}></img>
                        </a>
                      </CopyToClipboard>
                      <ReactTooltip id="toolTipCopy" place="top" effect="solid">
                      </ReactTooltip>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            : ''
          }

          <div className="line"></div>
          {!onloadPage && careerContent.banner ? 
            <div className="text-center mb-4 career-banner">
              <img src={careerContent.banner}></img>
            </div>
          : ''}
          <div className="content">
            <div className="title-content">{onloadPage ? <Skeleton height={35} borderRadius={10} width={250}></Skeleton> : 'Deskripsi'}</div>
            <div className="desc-content">
              {onloadPage ? <Skeleton count={5} height={20} borderRadius={5}></Skeleton> : careerContent.deskripsi}
            </div>
          </div>
          <div className="content">
            <div className="title-content">{onloadPage ? <Skeleton height={35} borderRadius={10} width={250}></Skeleton> : 'Syarat dan Ketentuan'}</div>
            <div className="desc-content" dangerouslySetInnerHTML={{ __html: !onloadPage ? careerContent.terms_condition : '' }}>
            </div>
            {onloadPage ? <Skeleton count={5} height={20} borderRadius={5}></Skeleton> : ''}
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
