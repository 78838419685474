import React, { useState } from "react";
import "../../assets/scss/nav/nav.css";
import iconBrand from "../../assets/icon/icon-brand.png";
import LinkRoute from "../Elements/Link";
export function Nav(props) {
  const { active } = props;
  const [headerClassName, setHeaderClassName] = useState("");
  const [show, setShow] = useState(false);
  const handleScroll = (headerClassName) => {
    if (headerClassName !== "menuscroll" && window.pageYOffset > 0) {
      setHeaderClassName("menuscroll");
    } else if (headerClassName === "menuscroll" && window.pageYOffset === 0) {
      setHeaderClassName("");
    }
  };
  // const handelAgreement = (event) => {
  //   setIsActive(current => !current);
  //   // alert("Asdasd")
  // }

  React.useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]); // IMPORTANT, This will cause react to update depending on change of this value

  return (
    <>
      <div className="">
        <nav className={`navbar navbar-expand-lg ${headerClassName}`}>
          <div className="d-flex align-items-center w-100 py-2 container">
            <LinkRoute
              variant="navbar-brand text-start d-flex align-items-center"
              route="/"
            >
              <img src={iconBrand} alt={iconBrand} className="icon-brand"></img>
            </LinkRoute>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setShow(!show)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${show ? "show" : ""}`}>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <LinkRoute
                    variant={`nav-link ${active === "home" ? "active" : ""}`}
                    route="/"
                  >
                    Home
                  </LinkRoute>
                </li>
                <li className="nav-item">
                  <LinkRoute
                    variant={`nav-link ${active === "brand" ? "active" : ""}`}
                    route="/brand"
                  >
                    Brand
                  </LinkRoute>
                </li>
                <li className="nav-item">
                  <LinkRoute
                    variant={`nav-link ${
                      active === "franchise" ? "active" : ""
                    }`}
                    route="/mitra"
                  >
                    Bermitra
                  </LinkRoute>
                </li>
                <li className="nav-item">
                  <LinkRoute
                    variant={`nav-link ${
                      active === "distributor" ? "active" : ""
                    }`}
                    route="/distributor"
                  >
                    Distributor
                  </LinkRoute>
                </li>
                <li className="nav-item">
                  <LinkRoute
                    variant={`nav-link ${active === "career" ? "active" : ""}`}
                    route="/career"
                  >
                    Karir
                  </LinkRoute>
                </li>
              </ul>
            </div>
            <LinkRoute route="/contact-us" variant="btn-rounded-green" id="bt-contact">
              Kontak Kami
            </LinkRoute>
          </div>
        </nav>
      </div>
    </>
  );
}
