import Skeleton from "react-loading-skeleton";

const SkeletonBrand = () => {
    return (
      <div className="card-brand">
        <Skeleton width={138} height={138} borderRadius={210}></Skeleton>
        <div className="title-brand"><Skeleton height={30} width={'100%'} borderRadius={5}></Skeleton></div>
      </div>
    );
  };
  
  export default SkeletonBrand;
  