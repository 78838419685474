const Card = (props) => {
  const { desc, title, src } = props;
  return (
    <div className="card-brand">
      <img src={src} alt={src}></img>
      <div className="title-brand">{title}</div>
      <div className="desc-brand">{desc}</div>
    </div>
  );
};

export default Card;
