import Skeleton from "react-loading-skeleton";

const SkeletonCareer = () => {
  return (
    <div className="item-career">
      <div className="hader-career">
        <div className="title-career"><Skeleton height={40} borderRadius={10}></Skeleton></div>
        <div className="deadline-career d-flex align-items-center">
          <Skeleton height={30} borderRadius={5}></Skeleton>
        </div>
      </div>
      <div className="line"></div>
        <Skeleton count={4} height={15} borderRadius={5}></Skeleton>
    </div>
  );
};

export default SkeletonCareer;
