import Skeleton from "react-loading-skeleton";

const SkeletonLokasi = () => {
  return (
    <div className="item-maps d-flex">
      <div>
        <Skeleton width={36} height={36} circle={true}></Skeleton>
      </div>
      <div className="ms-3 w-100">
        <div className="title-maps"><Skeleton height={30} width={'100%'} borderRadius={5}></Skeleton></div>
        <div className="address"><Skeleton height={15} width={'100%'} borderRadius={5}></Skeleton></div>
        <div className="telp"><Skeleton height={15} width={'100%'} borderRadius={5}></Skeleton></div>
      </div>
    </div>
  );
};

export default SkeletonLokasi;
