import React from "react";
import iconShare from "../../../assets/icon/icon-arrow-white.svg";
import bg from "../../../assets/img/bg-get-in-touch.svg";
import "../../../assets/scss/home/getintouch/getintouch.css";
import LinkRoute from "../../Elements/Link";
export function GetInTouch() {
  return (
    <div>
      <section className="get-in-touch">
        <img src={bg} alt={bg} className="bg-get-in-touch"></img>
        <div className="wrapper-content">
          <div className="text-head">Hubungi Kami</div>
          <div className=" mt-3 text-get-in-touch d-flex justify-content-center">
            <div>
              Kami menyukai tantangan besar dan kecil - apa tantanganmu?
            </div>
          </div>
          <div className="mt-4 d-flex justify-content-center see-more">
            <LinkRoute variant="btn-rounded-orange" route="/contact-us">
              Kontak Kami <img src={iconShare} alt={iconShare}></img>
            </LinkRoute>
          </div>
        </div>
      </section>
    </div>
  );
}
