import Skeleton from "react-loading-skeleton";

const CardSkeleton = (props) => {
    const { desc, title, src } = props;
    return (
      <div className="card-brand">
        <Skeleton borderRadius={10} height={128} width={128}></Skeleton>
        <div className="title-brand"><Skeleton height={30}></Skeleton></div>
        <div className="desc-brand"><Skeleton height={20} count={2}></Skeleton></div>
      </div>
    );
  };
  
  export default CardSkeleton;
  