import React from "react";
import { Link } from "react-router-dom";

const LinkRoute = (props) => {
  const { children, variant, id = "", route } = props;
  return (
    <Link className={`${variant}`} to={`${route}`} id={`${id}`}>
      {children}
    </Link>
  );
};

export default LinkRoute;
